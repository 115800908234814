import { makeAutoObservable, extendObservable, action, computed, toJS } from "mobx";//+

const defaultParams = {
  type: 'standard'
}

class Catalogues {

  getDefaultStoreProps = () => ({
    filterData: defaultParams,
  })

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps()});
  }

  @action
  setFilter(filter) {
    this.filterData = filter || defaultParams;
  }

  @computed
  get filter() {
    const filterToJS = toJS(this.filterData)
    return filterToJS;
  }
}

export default new Catalogues();