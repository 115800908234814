import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import {
  Box,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  getMessage,
} from 'helpers/helper';

import { routes } from 'config/index';
import WineLabelsService from 'services/wineLabelsService';
import ServiceProvidersService from 'services/serviceProvidersService';
import wineLabelsStore from 'store/wineLabelStore';
import formStore from 'store/wineLabelsFormStore';
import domainData from 'store/domainStore';
import wineLableQRCodeStore from 'store/wineLableQRCodeStore';

import { useModal } from 'hooks/common';

import Loading from 'components/common/loading';
import NoAccess from 'components/common/noAccessPage';

import M from 'messages';
import QRCodeModal from './QRCodeModal';
import SetInMarketModal from '../modals/setInMarketModal';

import FormContent from './content';
import MobilePreview from './mobilePreview';
import classes from './styles';

const CreateWineLabelItemWrapper = observer(({ isEdit, isCreate }) => {
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const currentSPId = domainData.getCurrentSP();
  const labelMode = isEdit ? 'edit' : isCreate ? 'create' : '';
  formStore.setLabelState(labelMode);
  const labelAccess = domainData.getSubscriptionAccess('label');
  if (!labelAccess) { return <NoAccess type='eu' />; }

  return (
    <CreateWineLabelItem
      isEdit={isEdit}
      isCreate={isCreate}
      isUserSuperAdmin={isUserSuperAdmin}
      currentSPId={currentSPId}
    />
  )
});

const CreateWineLabelItem = observer(({ isEdit = false, isCreate = false, currentSPId, isUserSuperAdmin }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currWineLabelData, setCurrWineLabelData] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  const [open, setOpen] = useState(false);
  const [labelId, setLabelId] = useState('');
  const [isOpenInMarket, openModalInMarket, closeModalInMarket] = useModal(false);

  const handleSetInMarket = async () => {
    try {
      await WineLabelsService.wineLabelSetInMarket({ ids: [id] });
      navigate(routes.wineLabels.path);
    } catch (err) {
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    closeModalInMarket()
  };

  const handleOpen = () => setOpen(true);
  const handleSave = async () => {
    setOpen(false);
    try {
      const QRData = wineLableQRCodeStore.getQRData();
      await wineLableQRCodeStore.updateQRCode(QRData, QRData.id);
      navigate(routes.wineLabels.path);
    } catch (err) {
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
  }

  const handleClose = async (event, reason) => {
    if (!((reason === 'backdropClick' || reason === 'escapeKeyDown'))) {
      setOpen(false);
      if (isCreate) {
        navigate(`${routes.wineLabelsEdit.path.replace(
          ':id',
          labelId
        )}`);
      }
    }
  }

  const setReadyData = (data) => {
    const readyData = data.wineLabelData;
    wineLabelsStore.setWineLabelData(readyData, readyData.serviceProvider);
    formStore.setWineLabelData(readyData);
    wineLableQRCodeStore.setWineLableQRData(readyData.qrCode);
    setCurrWineLabelData(readyData);
    if (readyData.status === 'in_market') {
      formStore.fetchInitialData(false, null, null, true, data);
    } else {
      formStore.fetchInitialData(isEdit);
    }
  }

  const getWineLabel = useCallback(
    async () => {
      setLoading(true);
      try {
        const response = await WineLabelsService.getWineLabel(id);
        if (response) {
          setReadyData(response.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, id]
  );

  const getSpList = useCallback(
    async () => {
      try {
        setLoading(true);
        formStore.setSpListLoading(true);
        if (isUserSuperAdmin) {
          const response = await ServiceProvidersService.getServiceProvidersNames();
          if (response) {
            formStore.setSpList(response.data.data);
          }
        } else {
          const response = await ServiceProvidersService.getServiceProvider(currentSPId);
          if (response) {
            formStore.setSpList([response.data]);
            // cataloguesStore.setProducerDefaultValue(response.data.name);
          }
        }
        setLoading(false);
        formStore.setSpListLoading(false);
      } catch (err) {
        setLoading(false)
        formStore.setSpListLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const createWineLabel = useCallback(
    async (newData) => {
      try {
        setDisableSave(true);
        const response = await formStore.createWineLabel(newData);
        if (response) {
          setLabelId(response.data.id);
          wineLableQRCodeStore.setWineLableQRData(response.data.QR);
          handleOpen();
        }
        setDisableSave(false);
      } catch (err) {
        setDisableSave(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const updateWineLabel = useCallback(
    async (newData) => {
      try {
        setDisableSave(true);
        const response = await formStore.updatePublishedWineLable(newData, id);
        if (response) {
          handleOpen();
        }
        setDisableSave(false);
      } catch (err) {
        setDisableSave(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, id]
  );

  useEffect(() => {
    setLoading(true);
    if (isCreate) {
      formStore.fetchInitialData(isEdit);
      formStore.setLabelState('create');
      formStore.setServiceProviderId(currentSPId);
      getSpList();
      setLoading(false);
    } else if (id) {
      getWineLabel(id);
    }
    return () => {
      wineLabelsStore.reset();
      formStore.resetErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDiscard = () => {
    navigate(routes.wineLabels.path);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const formHasError = formStore.wineLabelDataHasError();
    if (!formHasError) {
      try {
        const { formData } = formStore;
        if (isEdit) {
          updateWineLabel(formData);
        } else {
          createWineLabel(formData);
        }
      } catch (err) {
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
      return true;
    }
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };

  if (loading) return <Loading />;

  let readonly = false;
  let disabled = false;
  if (!isUserSuperAdmin && !isCreate && !currWineLabelData?.active || currWineLabelData?.status === 'in_market') {
    disabled = true;
    readonly = true;
  }

  return (
    <Box sx={classes.page}>
      <QRCodeModal
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        isCreate={isCreate}
        isEdit={isEdit}
      />
      <SetInMarketModal open={isOpenInMarket} handleClose={closeModalInMarket} handleSubmit={handleSetInMarket} classes={classes} />
      <Grid container spacing={1}>
        <Grid size={{ xs: 8, sm: 8 }}>
          <Box sx={classes.pageContent}>
            <FormContent isEdit={isEdit} disabled={disabled} isUserSuperAdmin={isUserSuperAdmin} />
          </Box>
        </Grid>
        <Grid size={{ xs: 4, sm: 4 }}>
          <Box style={{ height: `calc(100vh - 300px)`, margin: '25px 32px 0 0px', }}>
            <MobilePreview />
          </Box>
        </Grid>
      </Grid>
      {/* Footer */}
      <Box sx={classes.pageFooter}>
        <Box>
          {!readonly ? (
            <Grid container sx={classes.footerPart}>
              <Button
                variant="text"
                color="primary"
                onClick={disabled || handleDiscard}
              >
                {M.get('actions.discard')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disabled || disableSave}
                onClick={onSubmit}
              >
                Save & Generate QR code
              </Button>
              {isEdit &&
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disabled || disableSave}
                  onClick={openModalInMarket}
                >
                  Activate for Market
                </Button>
              }
            </Grid>
          ) : (
            <Grid container sx={classes.footerPart}>
              <Button
                variant="text"
                color="primary"
                onClick={handleDiscard}
              >
                {M.get('actions.discard')}
              </Button>
              {currWineLabelData?.active && <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleOpen}
              >
                Open QR code
              </Button>}
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
})

CreateWineLabelItem.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
};

export default CreateWineLabelItemWrapper;