import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
  },
  listItemClosedDrawer: {
    padding: '6px',
  },
  listItemText: {
    color: theme.palette.primary.sideBarTextColor,
    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize: '0.875rem'
    }
  },
  listItemIcon: {
    minWidth: '40px',
    transition: 'min-width 0.1s',
    opacity: '0.8',
    fill: theme.palette.primary.sideBarIconColor,
    color: theme.palette.primary.sideBarIconColor,
    '& > svg': {
      fill: theme.palette.primary.sideBarIconColor,
      color: theme.palette.primary.sideBarIconColor,
      '& > g': {
        fill: theme.palette.primary.sideBarIconColor,
        color: theme.palette.primary.sideBarIconColor,
        '& > path': {
          fill: theme.palette.primary.sideBarIconColor,
          color: theme.palette.primary.sideBarIconColor,
        }
      },
      '& > path': {
        fill: theme.palette.primary.sideBarIconColor,
        color: theme.palette.primary.sideBarIconColor,
      }
    }
  },
  listItemIconClosedDrawer: {
    minWidth: '56px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transition: 'min-width 100s',
    opacity: '0.8',
    '& > svg': {
      '& > g': {
        '& > path': {
          fill: theme.palette.primary.sideBarIconColor,
        }
      },
      '& > path': {
        fill: theme.palette.primary.sideBarIconColor,
      }
    }
  },
  hideOpacity: {
    opacity: 1,
  },
  linkActive: {
    color: theme.palette.primary.main,
  },
  iconActive: {
    color: theme.palette.primary.main,
    '& > svg': {
      '& > g': {
        '& > path': {
          fill: theme.palette.primary.main,
        }
      },
      '& > path': {
        fill: theme.palette.primary.main,
      }
    }
  },
  listItemsContainer: {
    borderTop: '1px solid #e3e3e3',
    padding: '6px 0'
  },
  hideContent: {
    display: 'none'
  },
  lastElement: {
    borderBottom: '1px solid #e3e3e3',
  },
  listItemIconShow: {
    '& > span': {
      display: 'none',
    },
    '&:hover': {
      '& > span': {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    }
  },
  listItemBold: {
    '& .MuiListItemText-primary': {
      fontWeight: '600',
    }
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  listItemSubMenu: {
    '& > div:first-of-type': {
      paddingTop: '4px',
    },
    '& > div:not(:first-of-type):not(:last-of-type)': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  listItemButton: {
    padding: '6px 16px 6px 20px',
  },
  listItemButtonNoHover: {
    '&:hover': {
      backgroundColor: 'unset',
    }
  },
  subMenuItem: {
    fontSize: '0.875rem',
    fontWeight: '600',
  }
}