import React from 'react';
import { observer } from 'mobx-react';
import { Typography, Box, Paper, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { PropTypes } from 'prop-types';
import formStore from 'store/wineLabelsFormStore';

import M from 'messages';
import Loading from 'components/common/loading';

import ProductInformation from './productInformation/productInformation';
import Ingredients from './ingredients/ingredients';
import Fat from './nutritions/fat';
import Carbohydrates from './nutritions/carbohydrates';
import Protein from './nutritions/protein';
import Salt from './nutritions/salt';
import Energy from './nutritions/energy';
import classes from '../styles';


const FormContent = observer(({ isEdit = false, isUserSuperAdmin = false, disabled = false }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        formStore.handleChange(name, value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        formStore.validateForm();
    };

    if (!formStore.formDataIsLoaded) {
        return <Loading />;
    }

    return (
        <form onSubmit={onSubmit} noValidate>
            <Box component={Paper} sx={classes.boxContainer} pb={2} px={3} pt={3}>
                <ProductInformation formStore={formStore} handleChange={handleChange} classes={classes} M={M} isUserSuperAdmin={isUserSuperAdmin} isEdit={isEdit} disabled={disabled} />
                <Ingredients formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
                <Grid size={{ xs: 12 }}>
                    <Divider />
                    <Box display="flex" alignItems="center" mt={3}>
                        <Typography variant="h5"><b>Nutrition Declaration</b></Typography>
                        <Tooltip
                            title={
                                <Typography
                                    sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                >
                                    To comply with EU regulations, provide mandatory nutritional values per 100ml: energy (kJ/kcal), carbohydrates, sugars, and salt, including fat, saturates, and protein if applicable.
                                </Typography>
                            }
                            placement='top'
                        >
                            <HelpOutlineIcon sx={classes.helpIcon} />
                        </Tooltip>
                    </Box>
                </Grid>
                <Fat formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
                <Carbohydrates formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
                <Protein formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
                <Salt formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
                <Energy formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
            </Box>
        </form>
    );
});

FormContent.propTypes = {
    isEdit: PropTypes.bool,
    disabled: PropTypes.bool,
    isUserSuperAdmin: PropTypes.bool,
};

export default FormContent;