import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  noAccess: {
    width: '100%',
    height: `calc(100vh - 64px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    fontWeight: '500',
    fontSize: '25px',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}