import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import CustomTable from 'components/common/customTableV2';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import ServiceProvidersService from 'services/serviceProvidersService';
import spStore from 'store/serviceProvidersStore';

import AddIcon from '@mui/icons-material/Add';
import M from 'messages';
import { adaptSPTableData } from 'helpers/adapter';
import { replacePath, getMessage } from 'helpers/helper';
import SidebarStore from 'store/ui/sidebarStore';
import { routes } from 'config/index';

import Modal from 'components/common/customModal';
import { useModal } from 'hooks/common';

import tableOptions from './config/config';
import CustomTableToolbar from './toolbar';

import classes from './styles';

const ServiceProviders = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [loading, setLoading] = useState(true);
  const [spData, setSpData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenApprove, openModalApprove, closeModalApprove] = useModal(false);
  const [isOpenReject, openModalReject, closeModalReject] = useModal(false);
  const isApproveActionActive = selectedRows.length > 0 && selectedRows.every(id => spData.data.find(row => row.id === id && row.isApproved === false));

  const filteredParamsStore = spStore.filter;
  const isSidebarOpened = SidebarStore.sidebarState;
  const { searchFields, filterFields } = tableOptions.serviceProviders;

  const setFilter = (filter) => {
    replacePath(navigate, filter);
    spStore.setFilter(filter);
  };

  const getSPLists = useCallback(
    async (params) => {
      try {
        // setLoading(true);
        replacePath(navigate, params);
        spStore.setFilter(params);
        const response = await ServiceProvidersService.getServiceProviders(params);
        if (response) {
          spStore.setStoreServiceProviders(response.data);
          setSpData({
            count: response.data.count,
            data: adaptSPTableData(response.data.data),
          });
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        // TODO: handle error case
        enqueueSnackbar('Get sp lists error', { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, spStore.getMakeDataRefresh()]
  );

  const queryParams = {
    params: {
      sort: pageParams?.sort || filteredParamsStore.params.sort,
      filter: pageParams?.filter || filteredParamsStore.params.filter,
      limit: pageParams?.limit || filteredParamsStore.params.limit,
      skip: pageParams?.skip || filteredParamsStore.params.skip,
      search: pageParams?.search || filteredParamsStore.params.search,
    }
  }

  useEffect(() => {
    getSPLists(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spStore.getMakeDataRefresh()]);

  const handleCreateSP = () => {
    navigate(`${location.pathname}/create`);
  }

  const onSearchCallback = (value) => {
    let oldValue = queryParams.params?.search?.value;
    if (oldValue === undefined) {
      oldValue = '';
    }
    if (searchFields && (oldValue !== value)) {
      const newFilter = { params: { ...queryParams.params, search: { ...queryParams.params.search, value, fields: searchFields }, skip: 0 } }
      if (!value) {
        delete newFilter.params.search;
      }
      setFilter(newFilter);
      getSPLists(newFilter)
    }
  };


  const onFilterCallback = (filterObj) => {
    if (filterObj && !Array.isArray(filterObj)) {
      const fieldKeyList = Object.keys(filterObj);
      let newFilter = {};
      fieldKeyList.forEach(i => {
        const fieldValue = filterObj[i];
        let oldValue = queryParams.params?.filter?.[i];
        if (oldValue === undefined) {
          oldValue = '';
        }
        if (filterFields && (oldValue !== fieldValue) && fieldValue !== undefined) {
          newFilter = { ...newFilter, params: { ...queryParams.params, ...newFilter.params, filter: { ...queryParams.params.filter, ...newFilter?.params?.filter, [i]: fieldValue }, skip: 0 } }
        }
      });
      if (Object.keys(newFilter).length) {
        setFilter(newFilter);
        getSPLists(newFilter);
      }
    }
  };

  const handleApprove = async () => {
    try {
      const response = await ServiceProvidersService.approvePartners(selectedRows);
      if (response) {
        enqueueSnackbar(M.get('serviceProvider.approveSuccess'), { variant: 'success' });
        spStore.setMakeDataRefresh();
      }
    } catch (err) {
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    setSelectedRows([]);
    closeModalApprove();
  }

  const handleReject = async () => {
    try {
      const response = await ServiceProvidersService.rejectPartners(selectedRows);
      if (response) {
        enqueueSnackbar(M.get('serviceProvider.rejectSuccess'), { variant: 'success' });
        spStore.setMakeDataRefresh();
      }
    } catch (err) {
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
    }
    setSelectedRows([]);
    closeModalReject();
  }

  const toolbarView = (
    <CustomTableToolbar
      onSearchCallback={onSearchCallback}
      filterFields={filterFields}
      filteredParams={queryParams}
      setFilteredParams={setFilter}
      onFilterCallback={onFilterCallback}
      openModalApprove={openModalApprove}
      isApproveActionActive={isApproveActionActive}
      openModalReject={openModalReject}
    />
  );

  const openPath = (row) => routes.serviceProviderEdit.path.replace(':id', row.id);

  return !loading ? (
    <Box
      sx={{
        ...classes.root,
        ...(isSidebarOpened && classes.rootDrawerOpen),
        ...(!isSidebarOpened && classes.rootDrawerClose)
      }}
    >
      <PageHeader
        generalPageTitle={M.get('serviceProvider.pageTitle')}
        element={(<Grid size={{ xs: 12 }} sx={classes.actionPart}>
          <Button
            onClick={handleCreateSP}
            startIcon={<AddIcon sx={classes.primaryIcon} />}
            sx={classes.primaryIconButton}
            variant="contained"
            color="primary"
          >
            {M.get('actions.create')}
          </Button>
        </Grid>
        )}
      />
      <Modal
        open={isOpenApprove}
        leftBtnText={M.get('actions.cancel')}
        rightBtnText={M.get('actions.approve')}
        handleSubmit={handleApprove}
        handleClose={closeModalApprove}
        title={M.get('serviceProvider.approvePartner')}
        dialogTitle="approveUserTitle"
        footerBtnStyle="footerBtnStyle"
        dialogContentClassName="noContentStyle"
      />
      <Modal
        open={isOpenReject}
        leftBtnText={M.get('actions.cancel')}
        rightBtnText={M.get('actions.reject')}
        handleSubmit={handleReject}
        handleClose={closeModalReject}
        title={M.get('serviceProvider.rejectPartner')}
        dialogTitle="approveUserTitle"
        footerBtnStyle="footerBtnStyle"
        dialogContentClassName="noContentStyle"
      />
      <Grid size={{ xs: 12 }}>
        <Box sx={classes.tableRoot}>
          <Grid container sx={classes.container}>
            <Box sx={classes.tabsContainer}>
              <CustomTable
                tableSources={spData}
                tableOptions={tableOptions.serviceProviders}
                loading={loading}
                filteredParams={queryParams}
                setFilteredParams={setFilter}
                getDataOnChange={getSPLists}
                toolbarView={toolbarView}
                openPath={openPath}
                withOpenAction={false}
                withEditAction
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                rowIsNotSelectable
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Box>
  ) : (
    loading && <Loading />
  );
});

export default ServiceProviders;