import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import ImageInput from 'components/common/form/imageInput';
import UploadIcon from '@mui/icons-material/Upload';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import cataloguesStore from 'store/catalogueStore';

import M from 'messages';
import classes from './styles';

const WineSpecs = observer(({ data, label, disabled }) => (
  <Box sx={classes.root} mt={1}>
    {data.map((item, index) => (
      <Accordion key={item.id} sx={{ margin: '0px', '&.MuiAccordion-root': classes.accordionRoot, '&.Mui-expanded': classes.accordionExpanded }} >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{item.title || label}</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Grid container>
            <Grid size={{ xs: 12 }} sx={{ height: '100%' }}>
              <ImageInput
                label={M.get('menuItem.itemImage.imageDefaultLabel')}
                data={item}
                disabled={disabled}
                onChange={(e) => cataloguesStore.onChangeWineSpec(e, index, 'image')}
                defaultValue={item.image}
                uploadText={<UploadIcon />}
                inputAreaClass="menuItemInputArea"
                isNewVersion
              />
            </Grid>
            <Grid size={{ xs: 12 }} mt={2}>
              <TextField
                multiline
                rows={5}
                variant="outlined"
                id="description"
                name="description"
                disabled={disabled}
                label={M.get('menuItem.wineSpecDescription')}
                value={item?.description}
                onChange={(event) => cataloguesStore.onChangeWineSpec(event, index, 'description')}
                sx={classes.descField}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
));

WineSpecs.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
};

export default WineSpecs;
