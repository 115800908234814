import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    minHeight: `calc(100vh - ${variables.headerHeight} - ${variables.footerHeight} - 40px)`,
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    padding: '12px 24px 24px 24px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal'
  },
  rootDrawerOpen: {
    maxWidth: `calc(100% - ${variables.drawerWidth} - 64px)`
  },
  rootDrawerClose: {
    maxWidth: `calc(100% - ${variables.closedDrawerWidth} - 64px)`
  },
  actionPart: {
    textAlign: 'end'
  },
  primaryIcon: {
    width: '18px',
    height: '18px',
  },
  primaryIconButton: {
    marginLeft: '16px',
    height: '36px',
    minWidth: '109px',
    fontWeight: '500',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    }
  },
  tableRoot: {
    marginTop: '12px',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.sideBarBgColor,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal'
  },
  container: {
    display: 'inherit',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px'
  },
  tabsContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.backgroundColor
  },
}
