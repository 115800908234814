import React from 'react';
import { observer } from 'mobx-react';
import { Box, IconButton, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useSortable, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import PropTypes from 'prop-types';

const SortableItem = ({ id, name, bgcolor, disabled }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    // Determine the text color based on the background color
    const textColor = bgcolor === '#D1383A' ? 'white' : 'inherit';

    const style = {
        transform: disabled ? 'none' : CSS.Translate.toString(transform),
        transition: disabled ? 'none' : transition,
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: disabled ? '#f0f0f0' : bgcolor, // Greyed-out background for disabled
        cursor: disabled ? 'not-allowed' : 'grab',
        margin: '4px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        height: '40px',
        minWidth: '200px',
        color: disabled ? '#aaa' : textColor, // Greyed-out text color for disabled
        opacity: disabled ? 0.6 : 1, // Adjust opacity when disabled
    };

    return (
        <Paper ref={setNodeRef} style={style} {...(disabled ? {} : attributes)} {...(disabled ? {} : listeners)} elevation={1}>
            <IconButton size="small" style={{ padding: '2px' }} disabled={disabled} {...(disabled ? {} : listeners)}>
                <DragIndicator fontSize="small" />
            </IconButton>
            <Typography variant="caption" component="span" noWrap>
                <b>{name}</b>
            </Typography>
        </Paper>
    );
};

const IngredientsOrder = observer(({ formStore, store, classes, disabled = false }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = ({ active, over }) => {
        if (!disabled && active.id !== over.id) {
            const oldIndex = store.ingredients.findIndex((item) => item.id === active.id);
            const newIndex = store.ingredients.findIndex((item) => item.id === over.id);
            const ingredients = store.reorderIngredients(oldIndex, newIndex);
            formStore.setOrder(ingredients);
        }
    };

    return (
        <Grid size={{ xs: 12 }}>
            <Box display="flex" alignItems="center">
                <Typography variant="h6">
                    <b>Order of Ingredients</b>
                </Typography>
                <Tooltip
                    title={
                        <Typography
                            sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                        >
                            The ingredients shall be displayed in descending order of weight, as recorded at the time of their use in the production of the product. The ingredients constituting less than 2 % of the finished product may be listed in a different order after the other ingredients.
                        </Typography>
                    }
                    placement='top'
                >
                    <HelpOutlineIcon sx={classes.helpIcon} />
                </Tooltip>
            </Box>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={store.ingredients.map((item) => item.id)}
                    strategy={rectSortingStrategy}
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                        mt={2}
                        justifyContent="flex-start"
                        bgcolor="rgb(246 247 250)"
                        p={2}
                        borderRadius="8px"
                    >
                        {store.ingredients.map((ingredient) => (
                            <SortableItem
                                key={ingredient.id}
                                id={ingredient.id}
                                name={ingredient.name}
                                bgcolor={ingredient.type === 'allergens' ? '#D1383A' : '#FFFFFF'}
                                disabled={disabled} // Pass the disabled prop
                            />
                        ))}
                    </Box>
                </SortableContext>
            </DndContext>
        </Grid>
    );
});

// Define PropTypes for the component
SortableItem.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    bgcolor: PropTypes.string,
    disabled: PropTypes.bool,
};

export default IngredientsOrder;
