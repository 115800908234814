import React from 'react';
import { observer } from 'mobx-react';
import { TextField, IconButton, FormControl, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid2';
import M from 'messages';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { passwordStrength } from 'check-password-strength';
import userProfileStore from 'store/userProfileStore';
import PropTypes from 'prop-types';

import PasswordValidation from 'components/common/passwordValidation';
import classes from './styles';

const ChangePasswordModal = observer(({ initialProfileData }) => {
  const dataErrorsPassChange = userProfileStore.getChangePasswordDataErrors();
  const dataPassChange = userProfileStore.getChangePasswordData();
  const { passwordLength } = initialProfileData;

  const requirements = passwordStrength(dataPassChange.newPassword);

  userProfileStore.setAllowModalSubmit(
    requirements.length >= passwordLength &&
    requirements.contains.includes('uppercase') &&
    requirements.contains.includes('lowercase') &&
    requirements.contains.includes('number') &&
    requirements.contains.includes('symbol')
  );

  const passwordHasError = () => {
    if (requirements.length < passwordLength) {
      return true;
    }
    if (
      !requirements.contains.includes('lowercase') ||
      !requirements.contains.includes('uppercase') ||
      !requirements.contains.includes('number') ||
      !requirements.contains.includes('symbol')
    ) {
      return true;
    }
    return false;
  };

  const showPassword = userProfileStore.getShowPasswordState();
  const showNewPassword = userProfileStore.getShowNewPasswordState();
  const showRepeatPassword = userProfileStore.getShowRepeatPasswordState();

  const repeatNewPasswordHasError = () =>
    userProfileStore.getChangeRepeatPSWChanged() &&
    (!!dataErrorsPassChange.repeatNewPassword || !userProfileStore.validateChangePasswords());

  const getRepeatNewPasswordTxt = () =>
    dataErrorsPassChange.repeatNewPassword
      ? M.get('form.errors.required')
      : !userProfileStore.validateChangePasswords() &&
        userProfileStore.getChangeRepeatPSWChanged()
        ? M.get('passwordValidation.passwordsNotMatch')
        : '';

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 12 }}>
        <FormControl fullWidth variant="outlined">
          <TextField
            label={M.get('users.oldPassword')}
            id="currentPassword"
            name="currentPassword"
            value={dataPassChange.currentPassword}
            required
            onChange={userProfileStore.onChangeFieldChangePasswordForm}
            variant="outlined"
            sx={classes.field}
            error={!!dataErrorsPassChange.currentPassword}
            helperText={
              dataErrorsPassChange.currentPassword &&
              M.get('form.errors.required')
            }
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            InputLabelProps={{
              style: classes.inputField
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => userProfileStore.setEyeIconStateByField('showPassword')}
                    edge="end"
                    size="large">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: { maxLength: 64 }
            }}
          />
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, sm: 12 }}>
        <FormControl variant="outlined">
          <TextField
            label={M.get('users.newPassword')}
            id="newPassword"
            name="newPassword"
            value={dataPassChange.newPassword}
            required
            onChange={userProfileStore.onChangeFieldChangePasswordForm}
            variant="outlined"
            error={userProfileStore.getChangeNewPSWChanged() && passwordHasError()}
            helperText={dataErrorsPassChange.newPassword ? M.get('form.errors.required') : ''}
            type={showNewPassword ? 'text' : 'password'}
            autoComplete="new-password"
            InputLabelProps={{
              style: classes.inputField
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => userProfileStore.setEyeIconStateByField('showNewPassword')}
                    edge="end"
                    size="large">
                    {showNewPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: { maxLength: 64 }
            }}
          />
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, sm: 12 }}>
        <FormControl variant="outlined">
          <TextField
            label={M.get('users.repeatNewPassword')}
            id="repeatNewPassword"
            name="repeatNewPassword"
            value={dataPassChange.repeatNewPassword}
            required
            onChange={userProfileStore.onChangeFieldChangePasswordForm}
            variant="outlined"
            error={repeatNewPasswordHasError()}
            helperText={getRepeatNewPasswordTxt()}
            type={showRepeatPassword ? 'text' : 'password'}
            autoComplete="new-password"
            InputLabelProps={{
              style: classes.inputField
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => userProfileStore.setEyeIconStateByField('showRepeatPassword')}
                    edge="end"
                    size="large">
                    {showRepeatPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: { maxLength: 64 }
            }}
          />
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12 }} container justifyContent="left" sx={classes.requirementContainer}>
        <Grid size={{ xs: 12 }}>
          <PasswordValidation requirements={requirements} passwordLength={passwordLength} />
        </Grid>
      </Grid>
    </Grid>
  );
});

ChangePasswordModal.propTypes = {
  initialProfileData: PropTypes.shape({}).isRequired,
};

export default ChangePasswordModal;
