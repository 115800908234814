import React from 'react';
import { observer } from 'mobx-react';
import { FormHelperText, Box, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ButtonsGroup from 'components/common/customBtnGroup';
import configOptions from 'pages/wineLabels/config/config';
import Alcoholization from './alcoholization';
import ProductTypeComponent from './productType';
import ProtectedGeographicalIndication from './protectedGeographicalIndication';
import CardInfo from './cardInfo/cardInfo';
import GrapeVarietyComponent from './grapeVariety';
import Provenance from './provenance'


const ProductInformation = observer(({ formStore, handleChange, classes, M, isUserSuperAdmin, isEdit, disabled = false }) => {
  const { formData, errors } = formStore;

  const handleChangeGroupButton = (name, value) => {
    handleChange({ target: { name, value } });
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5"><b>{M.get('labels.product.productInfoTitle')}</b></Typography>
      </Grid>
      <CardInfo formStore={formStore} handleChange={handleChange} classes={classes} M={M} isUserSuperAdmin={isUserSuperAdmin} isEdit={isEdit} disabled={disabled} />
      <ProductTypeComponent formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
      <GrapeVarietyComponent formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
      <Grid size={{ xs: 12 }}>
        <Divider />
        <Grid mt={2} mb={2} >
          <Box>
            <Typography sx={classes.fieldHeaderText} component='h1'>
              {M.get(configOptions.colorGroup.label)}
            </Typography>
          </Box>
          <ButtonsGroup disabled={disabled} groupObject={configOptions.colorGroup} handleChange={handleChangeGroupButton} value={formData.color} />
        </Grid>
        <Divider sx={errors.color ? classes.errorHelperText : {}} />
        {errors.color && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')}</FormHelperText>}
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Grid mb={2} >
          <Box>
            <Typography sx={classes.fieldHeaderText} component='h1'>
              {M.get(configOptions.sweetnessGroup.label)}
            </Typography>
          </Box>
          <ButtonsGroup disabled={disabled} groupObject={configOptions.sweetnessGroup} handleChange={handleChangeGroupButton} value={formData.sweetness} />
        </Grid>
        <Divider sx={errors.sweetness ? classes.errorHelperText : {}} />
        {errors.sweetness && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Grid mb={2}>
          <Box>
            <Typography sx={classes.fieldHeaderText} component='h1'>
              {M.get('labels.wine.style')}
            </Typography>
          </Box>
          <ButtonsGroup disabled={disabled} groupObject={configOptions.styleGroup} handleChange={handleChangeGroupButton} value={formData.style} />
        </Grid>
        <Divider sx={errors.style ? classes.errorHelperText : {}} />
        {errors.style && <FormHelperText sx={classes.errorHelperText}> {M.get('form.errors.required')}</FormHelperText>}
      </Grid>
      <ProtectedGeographicalIndication formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
      <Provenance formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
      <Alcoholization formStore={formStore} handleChange={handleChange} classes={classes} M={M} disabled={disabled} />
    </Grid>
  );
});

export default ProductInformation;
