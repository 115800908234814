import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid2';
import dashboardStore from 'store/dashboardStore';
import domainData from 'store/domainStore';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import NoAccess from 'components/common/noAccessPage';
import DashboardLayout from './dashboardLayout';

const DashboardWrapper = observer(() => {
  const dashboardAccess = domainData.getSubscriptionAccess('dashboard');
  if (!dashboardAccess) { return <NoAccess />; }
  return (
    <Dashboard/>
  );
});

const Dashboard = observer(() => {
  const { loading } = dashboardStore;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader generalPageTitle="Dashboard" onlyGeneralPageTitle />
      <Grid container spacing={2} sx={{ padding: 3, width: "100%" }}>
        <Grid size={{ xs: 12 }}>
          <DashboardLayout dashboardStore={dashboardStore} />
        </Grid>
      </Grid>
    </>
  );
});

export default DashboardWrapper;