import { get, put } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getCountriesOptions: async () => {
    const options = { url: apiEndpoints.countriesOptions };
    return get(options);
  },
  getWineTypesOptions: async () => {
    const options = { url: apiEndpoints.wineTypesOptions };
    return get(options);
  },
  getSettings: async () => {
    const options = { url: apiEndpoints.settings };
    return get(options);
  },
  getSubscriptionPlans: async () => {
    const options = { url: apiEndpoints.settingsSubricptionPlans };
    return get(options);
  },
  update: async (data) => {
    const options = { url: apiEndpoints.settingsUpdate, data };
    return put(options);
  },
}
