import React from 'react';
import { observer } from 'mobx-react';
import { TextField, Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid2';

const LocationInfo = observer(({ formStore, handleChange, M, disabled = false }) => {
    const maxSymbols = 255;
    const { formData, errors } = formStore;
    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.name;
    };

    const isOptionEqualToValue = (option, value) => option.id === value.id;

    const onChangeAutocomplete = (value) => {
        formStore.onChangeAutocomplete(value, 'countryId', 'countries');
    }

    return (
        <>
            <Grid size={{ xs: 12, sm: 6 }}>
                <Autocomplete
                    size="small"
                    id="countryId"
                    variant="outlined"
                    clearOnBlur
                    selectOnFocus
                    forcePopupIcon
                    handleHomeEndKeys
                    disabled={disabled}
                    value={formStore.getSelectedOption}
                    defaultValue={formStore.getSelectedOption}
                    onChange={(e, value) => onChangeAutocomplete(value)}
                    options={formStore.countriesOptions}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionDisabled={(option) => option.isDisabled}
                    renderOption={(props, option) => {
                        const { key, ...rest } = props;
                        return <li key={key} {...rest}>{option.name}</li>;
                    }}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label={`${M.get('menuItem.countries')}`}
                            variant="outlined"
                            error={!!errors.countryId}
                            helperText={!!errors.countryId && M.get('form.errors.required')}
                            inputProps={{ ...props.inputProps, maxLength: maxSymbols }}
                        />
                    )}
                />
            </Grid >
            <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                    size="small"
                    fullWidth
                    label={`${M.get('menuItem.region')}`}
                    id="region"
                    name="region"
                    variant="outlined"
                    disabled={disabled}
                    value={formData.region}
                    onChange={handleChange}
                    inputProps={{ maxLength: maxSymbols }}
                />
            </Grid >
            <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                    size="small"
                    fullWidth
                    label={`${M.get('menuItem.subRegion')}`}
                    id="subRegion"
                    name="subRegion"
                    variant="outlined"
                    disabled={disabled}
                    value={formData.subRegion}
                    onChange={handleChange}
                    inputProps={{ maxLength: maxSymbols }}
                />
            </Grid >
        </>
    );
});

export default LocationInfo;
