import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  page: {
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: `calc(100vh - 64px)`,
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    textAlign: 'start',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
  },
  rootDrawerOpen: {
    maxWidth: `calc(100% - ${variables.drawerWidth} - 64px)`
  },
  rootDrawerClose: {
    maxWidth: `calc(100% - ${variables.closedDrawerWidth} - 64px)`
  },
  pageContent: {
    flex: '1 1 auto',
    position: 'relative',
    overflowY: 'auto',
    height: `calc(100vh - 64px)`,
    margin: '24px 24px 0 24px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 5%)'
  },
  boxContainer: {
    borderRadius: '5px 5px 0 0',
    color: theme.palette.primary.sideBarTextColor,
    height: 'fit-content',
    boxShadow: 'none'
  },
  tabsHeader: {
    backgroundColor: theme.palette.primary.backgroundColor,
    color: 'black',
    boxShadow: '0 1px 0px #e4e4e4',
    marginBottom: 20,
  },
  fieldHeader: {
      display: 'flex',
      marginBottom: '16px'
  },
  fieldHeaderText: {
      marginRight: '10px',
      fontWeight: '600',
      fontSize: '18px',
      color: theme.palette.primary.sideBarTextColor
  },
  menuPaper: {
    maxHeight: '450px',
  },
  tabForm: {
    fontWeight: '600'
  },
  tabFormInvalid: {
    color: '#f44336'
  },
  helperDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '19px'
  },
  qrHelperDiv: {
    display: 'flex',
    justifyContent: 'end',
    minHeight: '19px'
  },
  errorHelperText: {
    color: '#f44336',
    minHeight: '19px'
  },
  invalidSelectContainer: {
    border: '1px solid #f44336',
    borderRadius: '4px',
    '& > fieldset': {
      border: 'none',
    },
    '&:before': {
      borderBottom: '2px solid #f44336',
    },
    '&:after': {
      borderBottom: '2px solid #f44336',
    },
    '&:hover': {
      "&:before": {
        borderColor: "#f44336",
        borderBottomWidth: "2px"
      },
    }
  },
  chipHeader: {
    width: '100%',
    fontWeight: '500',
    fontSize: '12px',
    color: 'black',
    opacity: '0.6'
  },
  headerTitle: {
    fontWeight: '500',
    fontSize: '16px',
    color: theme.palette.primary.sideBarTextColor,
  },
  headerDescription: {
    fontWeight: '400',
    fontSize: '14px',
    color: theme.palette.primary.sideBarTextColor,
    opacity: '0.6'
  },
  leftPart: {
    padding: '0',
    backgroundColor: theme.palette.primary.backgroundColor
  },
  content: {
    padding: '20px'
  },
  tagChip: {
    height: '24px'
  },
  disabledError: {
    color: 'red',
    marginLeft: '10px',
    fontSize: '14px'
  },
  optionItem: {
    '&[aria-disabled="true"]': {
      opacity: 1
    },
  },
  tableRoot: {
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.sideBarBgColor,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
  },
  container: {
    display: 'inherit',
  },
  tabsContainer: {
    width: '100%',
  },
  divider: {
    width: '100%',
    marginTop: '16px'
  },
  menuItemsMarginTop: {
    marginTop: '12px'
  },
  menuItemsArea: {
    marginTop: '16px'
  },
  chipRoot: {
    maxWidth: 'calc(100% - 40px)'
  },
  chipCount: {
    minWidth: 'fit-content',
    alignSelf: 'center',
    fontWeight: 500,
    fontSize: '16px'
  },
  menuItemsEmptyRoot: {
    width: '100%',
    alignSelf: 'center',
    color: theme.palette.primary.notActiveIconColor,
    minHeight: '342px',
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  iconHeader: {
    width: '100%'
  },
  wineIcon: {
    width: '200px',
    height: '200px',
  },
  emptyPageTitle: {
    marginTop: '20px',
    fontSize: '18px',
    fontWeight: '400'
  },
  select: {
    marginTop: '0px',
    border: 'none',
    width: '100%',
    height: '56px',
    boxShadow: "none",
    textAlign: 'left'
  },
  selectHeight: {
    '& .MuiInput-root': {
      marginTop: '0px',
    }
  },
  sectionHeaderText: {
    fontWeight: '600',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.sideBarTextColor,
  },
  fieldDescription: {
    margin: '16px 0 0 0',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
  },
  qrImage: {
    maxWidth: '300px',
    width: '100%',
    border: '1px solid #e4e4e4',
  },
  checkboxLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px',
    fontWeight: '600'
  },
  fieldHeaderIcon: {
    color: theme.palette.primary.sideBarIconColor,
    opacity: '0.6',
    cursor: 'pointer',
    marginLeft: '2px',
    fontSize: '1.2rem',
    verticalAlign: 'middle',
  },
  shouldResetWineRating: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldHeaderWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  },
  dragIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  pageFooter: {
    boxShadow: '0px -2px 16px rgba(0, 0, 0, 0.05)',
    zIndex: 1,
    textTransform: 'none'
  },
  footerPart: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '84px',
    backgroundColor: 'white',
    alignContent: 'center',
    padding: '0 32px 0 24px',
    minWidth: '416px',
    '& > button': {
        textTransform: 'none',
        margin: '0 0 0 20px'
    }
  },
}
