import { makeAutoObservable, action, computed, extendObservable, set } from "mobx";
import SettingsService from 'services/settingsService';
import AuthService from 'services/authService';
import { deleteAllSpacesFromStr } from 'helpers/helper';
import { isEmail, isValidPhoneNumber, isValidUrl } from "helpers/validatorHelper";

const defaultData = {
  name: '', description: '', type: 'winery', website: '', reservation: '',
  region: '', countryId: null, logo: '', fullAddress: '',
  contactPersonName: '', contactPersonEmail: '', contactPersonPhone: '',
  partnerFeatureTags: []
};

const requiredFields = [
  "name", "description", "type", "region", "countryId", 'fullAddress', "logo",
  "contactPersonName", "contactPersonEmail"
];

class ServiceProvidersStore {

  getDefaultStoreProps = () => ({
    serviceProviders: {
      count: 0,
      data: []
    },
    filterData: {
      params: {
        sort: {
          field: "id",
          order: "asc"
        },
        filter: {},
        limit: 50,
        skip: 0
      }
    },
    formData: JSON.parse(JSON.stringify(defaultData)),
    formDataErrors: {},
    isCreateFormChanged: false,
    countries: [],
    countriesLoaded: false,
    countriesLoading: false,
    captchaKeysLoaded: false,
    acceptedPrivacyPolicy: false,
  });

  constructor() {
    makeAutoObservable(this);
    extendObservable(this, { ...this.getDefaultStoreProps() });
  }

  @action
  reset() {
    set(this, this.getDefaultStoreProps());
  }

  @action
  createFormChanged = (value = true) => {
    this.isCreateFormChanged = value;
  }

  @computed
  getCreateFormChanged = () => this.isCreateFormChanged;

  @action
  onChangeField = (e) => {
    this.createFormChanged();
    const { name, value } = e.target;
    if (name === 'countryId') {
      this.formData.region = null;
    }
    this.formData[name] = value;

    const reqFields = requiredFields;
    if (!value && reqFields.includes(name)) {
      this.formDataErrors[name] = 'required';
    } else if (name === 'logo' && value instanceof File) {
      const maxSize = 5 * 1024 * 1024;
      if (value.size > maxSize) {
        this.formDataErrors[name] = 'maximumImageSize';
      } else {
        delete this.formDataErrors[name];
      }
    } else {
      delete this.formDataErrors[name];
    }
    return true;
  }

  @action
  onChangeEmailField = (e) => {
    this.createFormChanged();
    const { name, value } = e.target;
    const newValue = deleteAllSpacesFromStr(value);
    this.formData[name] = newValue;
    const reqFields = requiredFields;

    if (!newValue && reqFields.includes(name)) {
      this.formDataErrors[name] = 'required';
    } else if (newValue && !isEmail(newValue)) {
      this.formDataErrors[name] = 'invalidEmail';
    } else {
      delete this.formDataErrors[name];
    }
    return true;
  }

  @action
  onChangePhoneField = (value) => {
    this.formData.contactPersonPhone = value;
    const reqFields = requiredFields;

    // Check if only the country code exists
    const digitsOnly = value.replace(/\D/g, '');

    // Get the country code length from the phone number input
    const countryCodeMatch = value.match(/^\+\d+/);
    const countryCodeLength = countryCodeMatch ? countryCodeMatch[0].length - 1 : 0;

    if ((!value || digitsOnly.length <= countryCodeLength) && reqFields.includes('contactPersonPhone')) {
      this.formDataErrors.contactPersonPhone = 'required';
    } else if (value && !isValidPhoneNumber(value)) {
      this.formDataErrors.contactPersonPhone = 'invalidPhone';
    } else {
      delete this.formDataErrors.contactPersonPhone;
    }
    return true;
  }
  
  @action
  onChangeUrlField = (e) => {
    this.createFormChanged();
    const { name, value } = e.target;
    this.formData[name] = value;
    const reqFields = requiredFields;

    if (!value && reqFields.includes(name)) {
      this.formDataErrors[name] = 'required';
    } else if (value && !isValidUrl(value)) {
      this.formDataErrors[name] = 'invalidUrl';
    } else {
      delete this.formDataErrors[name];
    }
    return true;
  }

  @action
  onChangePrivacyCheckbox = (e) => {
    this.createFormChanged();
    const { name, checked } = e.target
    this[name] = checked
    if (!checked) {
      this.formDataErrors[name] = 'required';
    } else {
      delete this.formDataErrors[name];
    }
  }

  getFormData = () => this.formData;

  getFormDataErrors = () => this.formDataErrors;

  @action
  clearFormDataErrors = () => {
    this.formDataErrors = {};
  }

  formHasError = () => {
    let hasError = false;
    const reqFields = requiredFields;
    reqFields.forEach(i => {
      if (!this.formData[i]) {
        this.formDataErrors[i] = 'required';
        hasError = true;
      } else if (this.formDataErrors[i]) {
        hasError = true;
      } else {
        this.formDataErrors[i] = '';
      }
    });
    if (!this.acceptedPrivacyPolicy) {
      this.formDataErrors.acceptedPrivacyPolicy = 'required';
      hasError = true;
    } else if (this.formDataErrors.website || this.formDataErrors.reservation) {
      hasError = true;
    }
    return hasError;
  };

  @computed
  get countriesOptions() {
    const countriesOptions = this.countries?.map(i => ({
      id: i.id,
      name: i.name
    }));

    return countriesOptions;
  }

  getCountriesData = async () => {
    if (this.countriesLoaded) {
      return this.countries;
    }
    if (this.countriesLoading) {
      return new Promise(resolve => {
        const checkData = () => {
          if (this.countriesLoaded) {
            resolve(this.countries)
          } else {
            setTimeout(checkData, 1000)
          }
        };
        checkData();
      });
    }
    await this.loadCountriesData();
    return this.countries;
  }

  loadCountriesData = async () => {
    this.countriesLoading = true;
    try {
      const response = await SettingsService.getCountriesOptions();
      if (response) {
        this.countries = response.data.map(i => ({
          id: i.id,
          name: i.name
        }));
        this.countriesLoaded = true;
        this.countriesLoading = false;
      }
    } catch (err) {
      this.countriesLoading = false;
    }
  }

  isCountriesLoaded = () => this.countriesLoaded;

  clearCountriesData = () => {
    this.countries = [];
    this.countriesLoaded = false;
    this.countriesLoading = false;
  }

  @computed
  get isCountryOptionLoaded() {
    return this.countriesLoaded;
  }

  @computed
  get selectedCountry() {
    this.countriesSelected = this.countries.find(country => country.id === this.formData.countryId);
    return this.countriesSelected || '';
  }
  
  onChangeAutocomplete = (newValue, name, addType) => {
    this.createFormChanged();
    const value = newValue?.inputValue || newValue;
    if (!value && requiredFields.includes(name)) {
        this.formDataErrors[name] = 'required';
        this.formData[name] = null;
        this[`${addType}Selected`] = null;
    } else {
      this[`${addType}Selected`] = value;
      this.formData[name] = value?.id;
      delete this.formDataErrors[name];
    }
  }

  getTags = () => this.formData.partnerFeatureTags;

  setTags = (tags) => {
    // Filter out duplicate items based on the 'name' property
    const uniqueTags = tags.reduce((acc, tag) => {
      if (!acc.some(existingTag => existingTag.name.toLowerCase() === tag.name.toLowerCase())) {
        acc.push(tag);
      }
      return acc;
    }, []);
    this.formData.partnerFeatureTags = uniqueTags;
  }

  getCSRFToken = async () => {
    try {
      const response = await AuthService.getCSRFToken();
      this.formData.csrfToken = response.data.csrfToken;
      return response;
    } catch (err) {
      return err;
    }
  }

  getSingleUseToken = async () => {
    try {
      const response = await AuthService.getSingleUseToken();
      this.formData.singleUseToken = response.data.singleUseToken;
      return response;
    } catch (err) {
      return err;
    }
  }

  loadCaptchaKeys = async () => {
    try {
      const response = await AuthService.getCaptchaKeys();
      this.captchaKeys = response.data;
      this.captchaKeysLoaded = true;
      return response;
    } catch (err) {
      return err;
    }
  }

  getCaptchaKeys = () => this.captchaKeys;

  getCaptchaKeysLoaded = () => this.captchaKeysLoaded;

}

export default new ServiceProvidersStore();