import React from 'react';
import { observer } from 'mobx-react';
import { Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import IngredientsStore from 'store/ingredientsStore';
import Gases from './gases';
import IngredientsOrder from './ingredientsOrder';
import Ingredient from './ingredient';

const Ingredients = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const config = [{
        type: 'sweetenings',
        isCustom: false,
        headerText: M.get(`labels.ingredients.sweetenings`),
        tooltipText: M.get(`labels.ingredients.tooltips.sweetenings`),
    }, {
        type: 'acidityps',
        isCustom: false,
        headerText: M.get(`labels.ingredients.acidityps`),
        tooltipText: M.get(`labels.ingredients.tooltips.acidityps`),
    }, {
        type: 'preservatives',
        isCustom: false,
        headerText: M.get(`labels.ingredients.preservatives`),
        tooltipText: M.get(`labels.ingredients.tooltips.preservatives`),
    }, {
        type: 'stabilizings',
        isCustom: false,
        headerText: M.get(`labels.ingredients.stabilizings`),
        tooltipText: M.get(`labels.ingredients.tooltips.stabilizings`),
    },
    {
        type: 'liquors',
        isCustom: false,
        headerText: M.get(`labels.ingredients.liquors`),
        tooltipText: M.get(`labels.ingredients.tooltips.liquors`),
    }, {
        type: 'others',
        isCustom: false,
        headerText: M.get(`labels.ingredients.others`),
        tooltipText: '',
    }, {
        type: 'allergens',
        isCustom: false,
        headerText: M.get(`labels.ingredients.allergens`),
        tooltipText: M.get(`labels.ingredients.tooltips.allergens`),
    }, {
        type: 'gases',
        isCustom: true,
    }
    ];

    return (
        <Grid container spacing={2} mb={3}>
            <Grid size={{ xs: 12 }}>
                <Divider />
                <Typography mt={3} variant="h5"><b>{M.get('labels.ingredients.title')}</b></Typography>
            </Grid>
            {
                config.map((item, index) => {
                    const commonProps = {
                        formStore,
                        handleChange,
                        classes,
                        M,
                        disabled,
                        ingredientsStore: IngredientsStore,
                        headerText: item.headerText,
                        tooltipText: item.tooltipText,
                    };
                    return item.isCustom ? (
                        <Gases key={index} {...commonProps} />
                    ) : (
                        <Ingredient key={index} {...commonProps} type={item.type} />
                    )
                })
            }
            <IngredientsOrder formStore={formStore} store={IngredientsStore} classes={classes} disabled={disabled} />
        </Grid>
    )
});

export default Ingredients;