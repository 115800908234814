import React from 'react';
import { observer } from 'mobx-react';
import { TextField, Divider, FormControl, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonsGroup from 'components/common/customBtnGroup';
import wineLabelsFormStore from 'store/wineLabelsFormStore';

const ProtectedGeographicalIndication = observer(({ formStore, handleChange, classes, M, disabled }) => {
    const maxSymbols = 255;
    const { formData, errors } = formStore;

    const handleOptionChange = (name, value) => {
        wineLabelsFormStore.cleanProtectedGeographicalIndicationFields();
        const e = { target: { name, value } };
        handleChange(e);
    };

    const handleCustomInputChange = (event) => {
        handleChange(event);
        const { name, value } = event.target;
        const e = { target: { name, value } };
        handleChange(e);
    };

    const configOptions = {
        label: 'Protected geographical indication',
        name: 'protectedGeographicalIndication',
        fields: [
            { value: 'Protected designation of origin (PDO)', label: 'Protected designation of origin (PDO)' },
            { value: 'Protected geographical indication (PGI)', label: 'Protected geographical indication (PGI)' },
            { value: 'Other protected area', label: 'Other protected area' },
        ],
    };

    return (
        <>
            <Grid size={{ xs: 12, sm: 12 }}>
                <Box display="flex" alignItems="center">
                    <Typography sx={classes.fieldHeaderText} component='h1'>
                        {M.get('labels.protectedGeographicalIndication')}
                    </Typography>
                    <Tooltip
                        title={
                            <Typography
                                sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                            >
                                Regulation (EU) 2019/33: Should only be registered in a language that has at least a historical link with the geographical area in which the product is produced.
                            </Typography>
                        }
                        placement='top'
                    >
                        <HelpOutlineIcon sx={classes.helpIcon} />
                    </Tooltip>
                </Box>
                <ButtonsGroup
                    disabled={disabled}
                    groupObject={configOptions}
                    handleChange={handleOptionChange}
                    value={formData.protectedGeographicalIndication}
                    noTranslation
                />
            </Grid>
            {formData.protectedGeographicalIndication === 'Protected designation of origin (PDO)' && (
                <Grid size={{ sm: 6 }}>
                    <FormControl fullWidth>
                        <TextField
                            label="PDO Area"
                            name="protectedGeographicalIndicationText"
                            size="small"
                            required
                            value={formData.protectedGeographicalIndicationText}
                            error={!!errors.protectedGeographicalIndicationText}
                            helperText={
                                errors.protectedGeographicalIndicationText ? errors.protectedGeographicalIndicationText : null
                            }
                            onChange={handleCustomInputChange}
                            placeholder="Area"
                            inputProps={{ maxLength: maxSymbols }}
                            disabled={disabled}
                        />
                    </FormControl>
                </Grid>
            )}
            {formData.protectedGeographicalIndication === 'Protected geographical indication (PGI)' && (
                <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth>
                        <TextField
                            label="PGI Area"
                            name="protectedGeographicalIndicationText"
                            size="small"
                            required
                            value={formData.protectedGeographicalIndicationText}
                            error={!!errors.protectedGeographicalIndicationText}
                            helperText={
                                errors.protectedGeographicalIndicationText ? errors.protectedGeographicalIndicationText : null
                            }
                            onChange={handleCustomInputChange}
                            placeholder="Area Name"
                            inputProps={{ maxLength: maxSymbols }}
                            disabled={disabled}
                        />
                    </FormControl>
                </Grid >
            )}
            {formData.protectedGeographicalIndication === 'Other protected area' && (
                <>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <FormControl fullWidth>
                            <TextField
                                label="Other protected area"
                                size="small"
                                name="otherProtectedGeographicalIndication"
                                required
                                value={formData.otherProtectedGeographicalIndication}
                                error={!!errors.otherProtectedGeographicalIndication}
                                helperText={
                                    errors.otherProtectedGeographicalIndication ? errors.otherProtectedGeographicalIndication : null
                                }
                                onChange={handleCustomInputChange}
                                placeholder="Other protected area"
                                inputProps={{ maxLength: maxSymbols }}
                                disabled={disabled}
                            />
                        </FormControl>
                    </Grid >
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <FormControl fullWidth>
                            <TextField
                                label="Area Name"
                                name="protectedGeographicalIndicationText"
                                size="small"
                                required
                                value={formData.protectedGeographicalIndicationText}
                                error={!!errors.protectedGeographicalIndicationText}
                                helperText={
                                    errors.protectedGeographicalIndicationText ? errors.protectedGeographicalIndicationText : null
                                }
                                onChange={handleCustomInputChange}
                                placeholder="Area Name"
                                inputProps={{ maxLength: maxSymbols }}
                                disabled={disabled}
                            />
                        </FormControl>
                    </Grid >
                </>
            )}
            <Grid size={{ xs: 12 }}>
                <Divider />
            </Grid>
        </>
    );
});

export default ProtectedGeographicalIndication;
