import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PageHeader from 'components/common/pageHeader';
import domainData from 'store/domainStore';
import { routes } from 'config';
import classes from './styles';

const Home = observer(() => {
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const currentUser = domainData.getCurrentUser();
  const quickLinks = [
    {
      title: "Subscriptions",
      description: "Explore our tailored Wine List and EU Wine Label plans to find the perfect fit for your needs.",
      show: !isUserSuperAdmin,
      link: routes.subscriptions.path,
    },
    {
      title: "Partners",
      description: "Access and manage information about platform partners.",
      show: isUserSuperAdmin,
      link: routes.serviceProviders.path,
    },
    {
      title: "Profile",
      description: "View and update your partner details",
      show: !isUserSuperAdmin,
      link: routes.serviceProviderEdit.path.replace(':id', currentUser.serviceProviderId),
    },
    {
      title: "Users",
      description: "Manage user accounts and access relevant details.",
      show: isUserSuperAdmin,
      link: routes.users.path,
    },
    {
      title: "Wine Management",
      description: "Manage your wine collection and associated functionalities. This includes adding wines, creating wine lists for default display or events, and analyzing performance via the dashboard.",
      show: true,
      subMenus: [
        {
          title: "Wines",
          description: "Add or manage wines in your collection, and showcase them on your website by including them in a wine list.",
          show: true,
          link: routes.catalogue.path,
        },
        {
          title: "Wine Lists",
          description: "Add or manage two types of wine lists: Default Lists, displayed directly on your website, and Event Lists, accessible via QR codes for specific events.",
          show: true,
          link: routes.kiosksMenus.path,
        },
        {
          title: "Dashboard",
          description: "Monitor and analyze your wine-related activities. Gain actionable insights to optimize your operations.",
          show: true,
          link: routes.dashboard.path,
        }
      ]
    },
    {
      title: "EU Wine Labels",
      description: "Create QR-based wine labels to provide customers with essential wine information while meeting EU standards.",
      show: true,
      subMenus: [
        {
          title: "Labels",
          description: "Add or manage labels to enhance customer access to detailed wine information through QR codes.",
          show: true,
          link: routes.wineLabels.path,
        }
      ]
    },
    {
      title: "Account Details",
      description: "Manage your personal information.",
      note: '(Available in the dropdown menu on the right side of the header)',
      show: true,
      link: routes.profileEdit.path.replace(':id', currentUser.id),
    },
    {
      title: "Settings",
      description: "Customize your account preferences and configure platform-wide settings.",
      note: '(Available in the dropdown menu on the right side of the header)',
      show: isUserSuperAdmin,
      link: routes.settings.path,
    }
  ];
  const drawMenuLInk = (row) => (
    <li key={row.title} style={classes.listItem}>
      <a style={{...classes.link, ...classes.boldText}} href={row.link} rel="noopener noreferrer">{row.title}: </a>
      <span style={classes.description}>
        {row.description}
      </span>
      {row.note && <span style={{...classes.description, ...classes.note}}>{row.note}</span>}
    </li>
  )
  return (
    <Box
      sx={classes.root}
    >
      <PageHeader generalPageTitle='Welcome to Sharevino' onlyGeneralPageTitle />
      <Typography sx={classes.title}>
        Welcome to Sharevino: Your Wine Journey Starts Here!
      </Typography>
      <Typography sx={classes.description} mt={1}>
        Sharevino offers two exceptional solutions tailored to wine enthusiasts and professionals alike. Here&apos;s how we
        can help you make the most of our platform:
      </Typography>
      <Grid container spacing={5} justifyContent="center" mt={6} mb={3}>
        <Grid size={{ xs: 10, md: 5 }} sx={classes.card}>
          <Typography sx={classes.wineManagement}>
            Wine Management
          </Typography>
          <Typography sx={classes.description} mt={1}>
            Perfect for wine creators and connoisseurs who want to showcase their wines publicly. Manage your wines and
            lists with ease, and for Pro and Premium users, enjoy access to advanced dashboards and multiple wine lists.
          </Typography>
          <ul>
            <li style={classes.listItem}><span style={classes.description}>Create and manage wines</span></li>
            <li style={classes.listItem}><span style={classes.description}>Add wines to public lists</span></li>
            <li style={classes.listItem}><span style={classes.description}>Pro and Premium plans offer dashboards and multiple menu lists</span></li>
          </ul>
        </Grid>
        <Grid size={{ xs: 10, md: 5 }} sx={classes.card}>
          <Typography sx={classes.wineManagement}>
            EU Wine Labels
          </Typography>
          <Typography sx={classes.description} mt={1}>
            Designed for compliance and labeling needs, our EU Wine Label solution simplifies the process of ingredient
            management and QR code generation.
          </Typography>
          <ul>
            <li style={classes.listItem}><span style={classes.description}>Add wine ingredients</span></li>
            <li style={classes.listItem}><span style={classes.description}>Generate and download EU-compliant QR codes</span></li>
            <li style={classes.listItem}><span style={classes.description}>Subscription plans: Essential, Advanced, Unlimited</span></li>
          </ul>
        </Grid>
      </Grid>
      <Divider />
      <Typography sx={classes.wineManagement} mt={3}>
        Menu Overview
      </Typography>
      <Typography sx={classes.description} mt={1}>
        Explore the main sections of the platform, with a brief description of their purpose and functionality.
      </Typography>
      <ul>
        {quickLinks.map(row =>
          row.show
          ? row.subMenus?.length
            ? <li key={row.title} style={classes.listItem}>
              <span style={classes.boldText}>{row.title}: </span>
              <span style={classes.description}>{row.description}</span>
              <ul>
                {row.subMenus.map(subRow => drawMenuLInk(subRow))}
              </ul>
            </li>
            : drawMenuLInk(row)
          : null
        )}
      </ul>
    </Box>
  );
});

export default Home;