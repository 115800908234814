import React from 'react';
import { observer } from 'mobx-react';
import { Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MultiSelectButtonsGroup from 'components/common/multiSelectButtonsGroup';
import ButtonsGroup from 'components/common/customBtnGroup';
import wineLabelsFormStore from 'store/wineLabelsFormStore';

const Gases = observer(({ formStore, handleChange, classes, M, disabled = false, ingredientsStore }) => {
    const { formData, errors, ingredients } = formStore;
    const values = ingredients.filter((ingredient) => ingredient.type === 'gases');
    const gasesData = formData.ingredients.filter((ingredient) => ingredient.type === 'gases');
    const handleAllergenChange = (name, selectedValues, i, action) => {
        if (action === 'adding') {
            ingredientsStore.addIngredient(i.label, i.value, 'gases');
        } else {
            ingredientsStore.removeIngredient(i.label);
        }
        formStore.handleIngredientsChange(name, selectedValues);
    };

    const groupObject = {
        name: 'gases',
        fields: values.map(item => ({
            value: item.id, // assuming id is an integer
            label: item.name,
        })),
    };

    const gasesAndPackagingGases = {
        name: 'gasesAndPackagingGases',
        fields: [
            { value: 'Bottled in a protective atmosphere', label: 'Bottled in a protective atmosphere' },
            { value: 'Bottling may happen in a protective atmosphere', label: 'Bottling may happen in a protective atmosphere' },
            { value: 'Other', label: 'Other' },
        ]
    };

    const handleChangeGroupButton = (name, value) => {
        ingredientsStore.removeGasesIngredient();
        wineLabelsFormStore.clearIngredientCustomFields('gases');
        handleChange({ target: { name, value } });
    };

    return (
        <Grid size={{ xs: 12 }}>
            <Box display="flex" alignItems="center">
                <Typography sx={classes.fieldHeaderText} component='h1'>
                    {M.get('labels.gasesAndPackagingGases')}
                </Typography>
                <Tooltip
                    title={
                        <Typography
                            sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                        >
                            The indication of additives falling under the category “packaging gases” may be replaced by “Bottled in a protective atmosphere” or “Bottling may happen in a protective atmosphere.” If one of these specific particulars is used, the specific gases used do not have to be separately listed.
                        </Typography>
                    }
                    placement='top'
                >
                    <HelpOutlineIcon sx={classes.helpIcon} />
                </Tooltip>
            </Box>
            <Grid size={{ xs: 12 }}>
                <ButtonsGroup disabled={disabled} groupObject={gasesAndPackagingGases} handleChange={handleChangeGroupButton} value={formData.gasesAndPackagingGases} noTranslation />
            </Grid>
            {formData.gasesAndPackagingGases === "Other" && (
                <Grid size={{ xs: 12 }} mt={1}>
                    <MultiSelectButtonsGroup
                        name="gasesAndPackagingGases"
                        groupObject={groupObject}
                        defaultValues={gasesData || []}
                        formStore={formStore}
                        values={gasesData || []}
                        handleChange={handleAllergenChange}
                        disabled={disabled}
                        noTranslation
                        size="medium"
                    />
                    {errors.gases && (
                        <Typography color="error" variant="body2">
                            {errors.gases}
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
});

export default Gases;