import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Chip,
  FormControl,
  Autocomplete,
  Tooltip,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ClearIcon from '@mui/icons-material/Clear';
import M from 'messages';
import classes from './styles';

const ChipInput = ({ dataErrors, disabled = false, onChange, value = [], options, fieldName, placeholder = '', tooltip }) => {
  const handleTagsOnChange = (_event, newValue) => {
    const updatedTags = newValue.map((tag) => {
      if (typeof tag === 'string') {
        return { id: `new`, name: tag };
      }
      return tag;
    });
    onChange(updatedTags);
  };

  const handleClearTags = () => {
    onChange([]);
  };

  return (
    <Grid size={{ xs: 12, sm: 12 }}>
      <FormControl fullWidth variant="outlined" sx={classes.formControl}>
        <Autocomplete
          multiple
          freeSolo
          value={value}
          onChange={handleTagsOnChange}
          disabled={disabled}
          filterSelectedOptions
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="tags"
          options={options}
          getOptionLabel={(option) => option}
          renderTags={(values, getTagProps) =>
            values.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={index}
                  variant="outlined"
                  label={option.name}
                  {...tagProps}
                  sx={{ height: '24px', marginRight: 0.5 }}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              variant="outlined"
              error={!!dataErrors[fieldName]}
              helperText={dataErrors[fieldName] && M.get('form.errors.required')}
              InputProps={{
                ...params.InputProps,
                sx: { position: 'relative', paddingRight: '70px !important' },
                endAdornment: (
                  <>
                    <InputAdornment
                      position="end"
                      sx={{
                        position: 'absolute',
                        right: '10px',
                      }}
                    >
                      {value.length ?
                        <IconButton onClick={handleClearTags}>
                          <ClearIcon sx={{ width: '20px', height: '20px' }} />
                        </IconButton> : null
                      }
                      <Tooltip placement="top" title={tooltip}>
                        <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};

ChipInput.propTypes = {
  dataErrors: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string
};

export default ChipInput;
