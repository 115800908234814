import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import qs from 'qs';

// Modules
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// MUI Core
import {
  IconButton,
  InputAdornment,
  Button,
  Paper,
  Box,
  FormControl,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getMessage } from 'helpers/helper';
import emailSentImg from 'assets/emailSent.png';

import { routes } from 'config';
import { LoginForm } from 'config/forms';
import AuthService from 'services/authService';
import domainData from 'store/domainStore';
import loginFormStore from 'store/loginFormStore';
import withAuthHOC from 'components/common/authHOC/authHOC';
import PageHeader from 'components/common/pageHeader';
import { getFirstPageRouteByRole } from 'constants/roles';
import { isSuperAdmin } from 'helpers/usersRolesHelper';
import M from 'messages';
import classes from './style';

const Login = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [accountBlocked, setAccountBlocked] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const dataErrors = loginFormStore.getLoginFormDataErrors();
  const data = loginFormStore.getLoginFormData();
  const showPassword = loginFormStore.getShowPasswordState();
  const user = domainData.getCurrentUser();

  useEffect(() => {
    if (user) {
      navigate(getFirstPageRouteByRole(user));
    }
    return () => {
      loginFormStore.clearData()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getRequiredFields = () => {
    const requiredList = [];
    LoginForm.forEach((i) => {
      requiredList.push(i.name);
    });
    return requiredList;
  };

  const handleBlockAccount = (err) => {
    setAccountBlocked(true);
    setLoading(false);
    enqueueSnackbar(err?.response?.data?.error, { variant: 'warning' });
  }

  const handleNotApproved = () => {
    setLoading(false);
    enqueueSnackbar(M.get('form.errors.accountNotApproved'), { variant: 'warning' });
  }

  const handleExpiredPassword = (err) => {
    setLoading(false);
    const query = { email: data.email, passwordLength: err?.response?.data?.passwordLength };
    const searchString = qs.stringify(query);
    navigate({
      pathname: routes.changePassword.path,
      search: searchString
    });
    enqueueSnackbar(err.response.data.error, { variant: 'warning' });
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let loginResponse = {};
    const formHasError = loginFormStore.loginFormDataHasError(getRequiredFields());
    if (!formHasError) {
      try {
        loginResponse = await AuthService.login(data.email, data.password);
        if (!isSuperAdmin(loginResponse.data.user)) {
          domainData.setCurrentSP(loginResponse.data.user.serviceProviderId);
        }
        domainData.setCurrentUser(loginResponse.data.user);
        navigate(getFirstPageRouteByRole(loginResponse.data.user));
        setLoading(false);
      } catch (err) {
        if (err?.response?.data?.error?.name === 'NotApprovedUserException') {
          handleNotApproved();
          return true;
        }
        if (err?.response?.data?.accountBlocked) {
          handleBlockAccount(err);
          return true;
        }
        if (err?.response?.data?.passwordExpired) {
          handleExpiredPassword(err);
          return true;
        }
        if (err?.response?.data?.error?.name === 'InvalidUserPasswordException' || err?.response?.data?.error?.name === 'UserLoginNotFoundException') {
          loginFormStore.clearPassword();
        }
        setLoading(false);
        enqueueSnackbar(M.get('login.errors.common'), { variant: 'error' });
      }
      return true;
    }
    setLoading(false);
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };

  const getEmailHelperText = () => {
    let helperText = '';
    if (dataErrors.email && dataErrors.email === 'required') {
      helperText = M.get('form.errors.required');
    } else if (dataErrors.email && dataErrors.email === 'invalidEmail') {
      helperText = M.get(`login.errors.invalidEmail`);
    }
    return helperText;
  }

  const handleReset = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (data.email) {
      try {
        const result = await AuthService.forgotPassword(data.email);
        enqueueSnackbar(getMessage(result.data), { variant: 'success' });
        setLoading(false);
        setEmailSent(true);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
      return true;
    }
    setLoading(false);
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };

  if (emailSent) {
    return (
      <Box sx={classes.container} component={Paper} p={2} width={375} margin='12px auto' elevation={3}>
        <PageHeader
          generalPageTitle={M.get('forgotPassword.success.title')}
          onlyGeneralPageTitle
        />
        <img src={emailSentImg} alt="emailSent" style={classes.emailImg} />
        <h3 style={classes.title}> {M.get('forgotPassword.success.title')} </h3>
        <p style={classes.successDescription}>
          {M.get('forgotPassword.success.descriptionPart_1')} <span style={classes.emailColor}>{data.email}</span> {M.get('forgotPassword.success.descriptionPart_2')}
        </p>
      </Box>)
  }

  return !accountBlocked ? (
    <Box sx={classes.container} component={Paper} width={375} margin='auto' elevation={3}>
      <PageHeader
        generalPageTitle={M.get('login.signIn')}
        onlyGeneralPageTitle
      />
      <h3 style={classes.title}> {M.get('login.signIn')} </h3>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid size={{ xs: 12 }}>
                <FormControl variant="standard" fullWidth sx={classes.margin}>
                  <TextField
                    variant="outlined"
                    label={M.get('login.email')}
                    id="email"
                    name="email"
                    value={data.email}
                    required
                    onChange={loginFormStore.onChangeLoginFormEmailField}
                    error={!!dataErrors.email}
                    helperText={getEmailHelperText()}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <FormControl variant="standard" fullWidth sx={classes.margin}>
                  <TextField
                    variant="outlined"
                    label={M.get('login.password')}
                    id="password"
                    name="password"
                    value={data.password}
                    required
                    onChange={loginFormStore.onChangeLoginFormField}
                    error={!!dataErrors.password}
                    helperText={dataErrors.password && M.get("form.errors.required")}
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{ root: classes.eyeIcon }}
                            onClick={() => loginFormStore.setEyeIconStateByField('showPassword')}
                            edge="end"
                            size="large">
                            {showPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Button color="primary" fullWidth type="submit" variant="contained" sx={classes.submit} disabled={loading}>
                  {M.get('login.signIn')}
                </Button>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <FormControl fullWidth variant="filled">
                  <Link style={classes.forgotPasswordLink} id="forgotPassword" to={routes.forgotPassword.path}>
                    {M.get('pages.forgotPasswordText')}
                  </Link>
                </FormControl>
                <FormControl fullWidth variant="filled">
                  <a style={classes.contactUsLink} href={routes.signUp.path} rel="noopener noreferrer">{M.get('login.signUpDesc')}</a>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  ) : (
    <Box sx={classes.container} component={Paper} p={2} mt={12} width={375} margin='auto' elevation={3}>
      <PageHeader
        generalPageTitle={M.get('login.blocked.title')}
        onlyGeneralPageTitle
      />
      <h3 style={classes.title}> {M.get('login.blocked.title')} </h3>
      <p style={classes.blockedDescription}> {M.get('login.blocked.description')}</p>
      <form onSubmit={handleReset}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid size={{ xs: 12 }}>
                <Button color="primary" fullWidth type="submit" variant="contained" disabled={loading} sx={loading ? classes.resetDisabled : classes.reset}>
                  {M.get('login.blocked.resetLink')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

export default withAuthHOC(Login);