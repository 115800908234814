import React from 'react';
import { observer } from 'mobx-react';
import { TextField, InputAdornment, FormControlLabel, Switch, Box, Typography, FormControl, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Salt = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const { formData, errors } = formStore;
    const handleNegligibleSaltChange = (event) => {
        handleChange({ target: { name: event.target.name, value: event.target.checked } });
        if (event.target.checked) {
            handleChange({ target: { name: 'salt', value: '' } });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                <Box sx={classes.fieldHeader}>
                    <Typography sx={classes.fieldHeaderText}>{`${M.get('labels.saltTitle')} *`}</Typography>
                </Box>
                <FormControl fullWidth variant="outlined">
                    <FormControlLabel
                        control={
                            <Switch
                                name="negligibleSalt"
                                checked={formData.negligibleSalt}
                                onChange={handleNegligibleSaltChange}
                                color="primary"
                            />
                        }
                        label={M.get('labels.saltLabel')}
                        disabled={disabled}
                    />
                </FormControl>
            </Grid>
            {!formData.negligibleSalt && (
                <>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                size="small"
                                label={M.get('labels.salt')}
                                name="salt"
                                required
                                value={formData.salt || ''}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    const floatRegex = /^\d*\.?\d*$/;
                                    if (floatRegex.test(value) || value === '') {
                                        handleChange(e)
                                    }
                                }}
                                variant="outlined"
                                error={!!errors?.salt}
                                helperText={
                                    errors?.salt ?
                                        errors.salt : null
                                }
                                disabled={disabled}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <>
                                                        <Typography
                                                            sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                                        >
                                                            <b>According to Regulation (EU) 1169/2011</b>, ‘salt’ means the salt equivalent content calculated using the formula: salt = sodium × 2,5.
                                                        </Typography>
                                                    </>
                                                }
                                            >
                                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid size={{ xs: 12 }}>
                <Divider sx={classes.divider} />
            </Grid>
        </Grid >
    );
});

export default Salt;
