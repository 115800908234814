import React, { useState, useCallback, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography, Paper } from '@mui/material';
import { routes } from 'config/index';
import { getMessage } from 'helpers/helper';
import Loading from 'components/common/loading';
import SubscriptionService from 'services/subscriptionService';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import M from 'messages';
import classes from './styles';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [manageBillingLoading, setManageBillingLoading] = useState(false);
  const { status, sessionId } = useParams();
  const isSuccessStatus = status === 'success';


  const confirmSubscription = useCallback(
    async () => {
      try {
        setLoading(true);
        await SubscriptionService.confirmSubscription({ sessionId });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, sessionId]
  );
  useEffect(() => {
    if (sessionId) {
      confirmSubscription();
    }
  }, [confirmSubscription, sessionId]);

  const handleBackButton = () => {
    navigate(routes.subscriptions.path);
  };

  const handleManageBillingButton = async () => {
    try {
      setManageBillingLoading(true);
      const response = await SubscriptionService.createCustomerPortal();
      window.location.href = response.data.url;
      setManageBillingLoading(false);
    } catch (err) {
      setManageBillingLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
        variant: 'error',
      });
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Box sx={classes.subscriptionResponseRoot}>
      <Box
        p={2}
        mt={12}
        elevation={3}
        component={Paper}
        sx={classes.subscriptionResponseSuccessContainer}
      >
        {isSuccessStatus ? <CheckCircleIcon sx={classes.subscriptionResponseIcon} /> : <CancelIcon sx={{ ...classes.subscriptionResponseIcon, ...classes.subscriptionResponseErrorColor }} />}
        <Typography sx={classes.subscriptionResponseSuccessText}>
          {isSuccessStatus
            ? M.get('subscriptions.paymentSuccess')
            : M.get('subscriptions.paymentCanceled')}
        </Typography>
        <Box sx={classes.subscriptionResponseButtons} gap={2}>
          <Button variant="outlined" onClick={handleBackButton}>
            {M.get('subscriptions.returnPortal')}
          </Button>
          {isSuccessStatus && (
            <Button variant="outlined" onClick={handleManageBillingButton}>
              {manageBillingLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) :
                M.get('subscriptions.manageBilling')
              }
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SubscriptionSuccess;
