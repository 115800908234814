import React, { useState, useEffect } from 'react';
// Modules
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { passwordStrength } from 'check-password-strength'
import qs from 'qs';
// MUI Core
import {
  Button,
  Paper,
  Box,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import withAuthHOC from 'components/common/authHOC/authHOC';
import Loading from 'components/common/loading';
import PasswordValidation from 'components/common/passwordValidation';
import PageHeader from 'components/common/pageHeader';

import forgotPasswordStore from 'store/forgotPasswordStore';
import AuthService from 'services/authService';
import { getMessage } from 'helpers/helper';
import { ResetPasswordForm } from 'config/forms';
import { routes } from 'config';
import M from 'messages';

import classes from './style';

const ResetPassword = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const dataErrors = forgotPasswordStore.getResetPasswordDataErrors();
  const data = forgotPasswordStore.getResetPasswordData();
  const requirements = passwordStrength(data.newPassword);

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [passwordLength, setPasswordLength] = useState(10);

  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const allowSubmit = requirements.length >= passwordLength && requirements.contains.includes('uppercase') && requirements.contains.includes('lowercase') && requirements.contains.includes('number') && requirements.contains.includes('symbol');

  useEffect(() => {
    const checkResetToken = async () => {
      try {
        const res = await AuthService.checkResetToken(pageParams.token);
        setPasswordLength(res.data.passwordLength);
        setIsTokenValid(true);
        setPageLoading(false);
      } catch (err) {
        setPageLoading(false);
        setIsTokenValid(false);
      }
    };

    checkResetToken();

    return () => {
      forgotPasswordStore.clearData();
      forgotPasswordStore.clearDataErrors();
    }
  }, [pageParams.token])

  const getRequiredFields = () => {
    const requiredList = [];
    ResetPasswordForm.forEach((i) => {
      requiredList.push(i.name);
    });
    return requiredList;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formHasError = forgotPasswordStore.resetPasswordDataHasError(getRequiredFields());
    if (!formHasError) {
      try {
        const resetPasswordResponse = await AuthService.resetPassword({ ...data, token: pageParams.token });
        enqueueSnackbar(getMessage(resetPasswordResponse.data), { variant: 'success' });
        setLoading(false);
        navigate(routes.login.path);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
      return true;
    }
    setLoading(false);
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };

  const passwordHasError = () => {
    if (requirements.length < passwordLength) {
      return true;
    }
    if (!requirements.contains.includes('lowercase') || !requirements.contains.includes('uppercase') || !requirements.contains.includes('number') || !requirements.contains.includes('symbol')) {
      return true;
    }
    return false;
  }

  const showNewPassword = forgotPasswordStore.getShowNewPasswordState();
  const showRepeatPassword = forgotPasswordStore.getShowRepeatPasswordState();

  const repeatNewPasswordHasError = () => forgotPasswordStore.getResetNewPSWChanged() && (!!dataErrors.repeatNewPassword || !forgotPasswordStore.validateResetPasswords());
  const getRepeatNewPasswordTxt = () =>
    dataErrors.repeatNewPassword
      ? M.get("form.errors.required") : !forgotPasswordStore.validateResetPasswords() && forgotPasswordStore.getResetNewPSWChanged()
        ? M.get("passwordValidation.passwordsNotMatch") : '';

  if (pageLoading) return <Loading customClass={classes.authLoading} />;

  return isTokenValid ? (
    <Box sx={classes.container} component={Paper} width={375} margin='auto' elevation={3}>
      <PageHeader
        generalPageTitle={M.get('resetPassword.title')}
        onlyGeneralPageTitle
      />
      <Typography component='h3' sx={classes.title}> {M.get('resetPassword.title')} </Typography>
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormControl variant="standard">
                  <TextField
                    variant="outlined"
                    label={M.get("passwordValidation.password")}
                    id="newPassword"
                    name="newPassword"
                    value={data.newPassword}
                    required
                    onChange={forgotPasswordStore.onChangeFieldRPW}
                    error={forgotPasswordStore.getResetPSWChanged() && passwordHasError()}
                    helperText={dataErrors.newPassword ? M.get("form.errors.required") : ''}
                    type={showNewPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{ root: classes.eyeIcon }}
                            onClick={() => forgotPasswordStore.setEyeIconStateByField('showNewPassword')}
                            edge="end"
                            size="large">
                            {showNewPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormControl variant="filled">
                  <TextField
                    variant="outlined"
                    label={M.get("passwordValidation.confirmPassword")}
                    id="repeatNewPassword"
                    name="repeatNewPassword"
                    value={data.repeatNewPassword}
                    required
                    onChange={forgotPasswordStore.onChangeFieldRPW}
                    error={repeatNewPasswordHasError()}
                    helperText={getRepeatNewPasswordTxt()}
                    type={showRepeatPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{ root: classes.eyeIcon }}
                            onClick={() => forgotPasswordStore.setEyeIconStateByField('showRepeatPassword')}
                            edge="end"
                            size="large">
                            {showRepeatPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container justifyContent="left" sx={classes.requirementContainer}>
            <Grid size={{ xs: 12 }}>
              <PasswordValidation requirements={requirements} passwordLength={passwordLength} />
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              sx={(!forgotPasswordStore.isSubmitBtnActive() || !allowSubmit || loading) ? classes.submitDisabled : classes.submit}
              disabled={!forgotPasswordStore.isSubmitBtnActive() || !allowSubmit || loading}
            >
              {loading ? <CircularProgress size={24} /> : M.get('actions.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  ) : (
    <Box sx={classes.container} component={Paper} width={375} margin='auto' elevation={3}>
      <PageHeader
        generalPageTitle={M.get('resetPassword.expiredToken')}
        onlyGeneralPageTitle
      />
      <Typography component='h3' sx={classes.title}> {M.get('resetPassword.title')} </Typography>
      <Typography sx={classes.expiredTxt} variant="body1"> {M.get('resetPassword.expiredToken')} </Typography>
    </Box>
  );
});

export default withAuthHOC(ResetPassword);