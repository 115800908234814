import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Drawer, List, Toolbar, useMediaQuery } from '@mui/material';
import SideBarLink from 'components/common/sidebarLink';
import SidebarStore from 'store/ui/sidebarStore';
import domainData from 'store/domainStore';
import M from 'messages';
import SideBarFooter from './sidebarFooter';
import sideBarData from './data';
import classes from './styles';

const SideBar = observer(() => {
  const downSM = useMediaQuery('(max-width:980px)');

  const isSidebarOpened = SidebarStore.sidebarState;
  const currentUser = domainData.getCurrentUser();
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const serviceProvider = domainData.getCurrentSpData();
  const [resolvedItems, setResolvedItems] = useState([]);

  useEffect(() => {
    const resolveSideBarItems = async () => {
      const items = await Promise.all(sideBarData.map(async (item) => {
        if (item.roles.includes(currentUser?.usersRole.name) && (!item.showSideBarSection || await item.showSideBarSection({ serviceProvider, isUserSuperAdmin }))) {
          return item;
        }
        return null;
      }));
      setResolvedItems(items.filter(item => item !== null));
    };

    resolveSideBarItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, serviceProvider, isUserSuperAdmin]);


  const renderDrawer = () => (
    <List sx={classes.drawerList}>
      {resolvedItems.map((item, index) => (
        <div key={item.id}>
          <SideBarLink
            item={item}
            icon={item.icon}
            link={item.link}
            label={M.get(item.label)}
            itemKey={item.id}
            containerClassName="listItemsContainer"
            iconPosition={item.iconPosition}
            height={item.height}
            sidebarStore={SidebarStore}
            isLastElement={index === resolvedItems.length - 1}
            subMenu={item.subMenu}
          />
        </div>
      ))}
    </List>
  );

  return (
    <Drawer
        variant='permanent'
        anchor='left'
        sx={{
          '& .MuiDrawer-paper': {
            ...classes.sidebarContainer,
            ...classes.noBorder,
            ...(isSidebarOpened && {
              ...classes.drawerOpen
            }),
            ...(!isSidebarOpened && {
              ...classes.drawerClose
            })
          },
          ...classes.drawer,
          ...(isSidebarOpened ? classes.drawerOpen : classes.drawerClose),
          ...(downSM ? classes.mobileDrawer : {})
        }}
      >
      <Toolbar sx={classes.toolBar}/>
      <Box sx={classes.drawerContainer}>{renderDrawer()}</Box>
      <SideBarFooter isSidebarOpened={isSidebarOpened}/>
    </Drawer>
  );
})

export default SideBar;