import React from 'react';
import { observer } from 'mobx-react';
import { Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MultiSelectButtonsGroup from 'components/common/multiSelectButtonsGroup';

const Ingredient = observer(({ formStore, classes, disabled, ingredientsStore, type, headerText, tooltipText }) => {
    const { formData, ingredients } = formStore;
    const values = ingredients.filter((ingredient) => ingredient.type === type);
    const data = formData.ingredients.filter((ingredient) => ingredient.type === type);
    const handleAllergenChange = (name, selectedValues, i, action) => {
        if (action === 'adding') {
            ingredientsStore.addIngredient(i.label, i.value, type);
        } else {
            ingredientsStore.removeIngredient(i.label);
        }
        formStore.handleIngredientsChange(name, selectedValues);
    };

    const groupObject = {
        name: type,
        fields: values.map(item => ({
            value: item.id, // assuming id is an integer
            label: item.name,
        })),
    };

    return (
        <>
            <Grid size={{ xs: 12 }}>
                <Box display="flex" alignItems="center">
                    <Typography sx={classes.fieldHeaderText} component='h1'>
                        {headerText}
                    </Typography>
                    {
                        tooltipText && <Tooltip
                            title={
                                <Typography
                                    sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                >
                                    {tooltipText}
                                </Typography>
                            }
                            placement='top'
                        >
                            <HelpOutlineIcon sx={classes.helpIcon} />
                        </Tooltip>
                    }
                </Box>
                <MultiSelectButtonsGroup
                    name={type}
                    groupObject={groupObject}
                    defaultValues={data || []}
                    values={data || []}
                    handleChange={handleAllergenChange}
                    formStore={formStore}
                    disabled={disabled}
                    noTranslation
                    size="medium"
                />
            </Grid>
        </>
    );
});

export default Ingredient;