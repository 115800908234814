import { get, put } from 'services/client';
import { apiEndpoints } from 'config';

export default {
  getUsers: async (params) => {
    const options = { url: apiEndpoints.users, params: {...params} };
    return get(options);
  },
  getUser: async (id) => {
    const options = { url: apiEndpoints.user.replace(':id', id)};
    return get(options);
  },
  getUserSubscriptions: async (id) => {
    const options = { url: apiEndpoints.userSubscriptions.replace(':id', id)};
    return get(options);
  },
  updateUser: async (data, id) => {
    const options = { url: apiEndpoints.user.replace(':id', id), data};
    return put(options);
  },
  changePassword: async (data, id) => {
    const rightUrl = apiEndpoints.changeUserPassword.replace(':id', id);
    const options = { url: rightUrl, data};
    return put(options);
  },
  changeEmailSendCode: async (data) => {
    const options = { url: apiEndpoints.changeEmail, data};
    return put(options);
  },
  confirmEmailChange: async (data) => {
    const options = { url: apiEndpoints.confirmEmailChange, data};
    return put(options);
  }
}