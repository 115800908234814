import React from 'react';
import { observer } from 'mobx-react';
import { TextField, Divider, FormControl, Box, Typography, Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonsGroup from 'components/common/customBtnGroup';
import wineLabelsFormStore from 'store/wineLabelsFormStore';

const Provenance = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const maxSymbols = 255;
    const { formData, errors } = formStore;

    const handleOptionChange = (name, value) => {
        const e = { target: { name, value } };
        handleChange(e);
        if (value === '') {
            wineLabelsFormStore.clearSelectedProvance();
        } else if (value === 'custom') {
            wineLabelsFormStore.clearSelectedProvanceCountryId();
        } else {
            wineLabelsFormStore.clearSelectedProvanceCustomLabel();
        }
    };

    const handleCustomInputChange = (event) => {
        handleChange(event);
        const e = { target: { name: 'provenanceOption', value: 'custom' } };
        handleChange(e); // Automatically select 'Custom' when typing in custom input
    };

    const onChangeAutocomplete = (value) => {
        formStore.onChangeAutocomplete(value, 'provenanceCountryId', 'countries');
    };

    const configOptions = {
        label: 'Origin/Indication of Provenance',
        name: 'provenanceOption',
        fields: [
            { value: 'wine of', label: 'Wine of [...]' },
            { value: 'produced in', label: 'Produced in [...]' },
            { value: 'product of', label: 'Product of [...]' },
            { value: 'sekt of', label: 'Sekt of [...]' },
            { value: 'custom', label: 'Custom' },
        ],
    };

    return (
        <>
            <Grid size={{ xs: 12, sm: 12 }}>
                <Box display="flex" alignItems="center">
                    <Typography sx={classes.fieldHeaderText} component='h1'>
                        {M.get('labels.provenanceOption')}
                    </Typography>
                    <Tooltip
                        title={
                            <Typography
                                sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                            >
                                <b>Article 45, Regulation (EU) No 2019/33 and Annex VII to Regulation (EU) 1308/2013</b>
                            </Typography>
                        }
                        placement='top'
                    >
                        <HelpOutlineIcon sx={classes.helpIcon} />
                    </Tooltip>
                </Box>
                <ButtonsGroup
                    disabled={disabled}
                    groupObject={configOptions}
                    handleChange={handleOptionChange}
                    value={formData.provenanceOption}
                    noTranslation
                />
            </Grid>

            {formData.provenanceOption !== 'custom' && formData.provenanceOption && (
                <Grid size={{ sm: 6 }}>
                    <FormControl fullWidth key={!!errors.provenanceCountryId}>
                        <Autocomplete
                            id="provenanceCountryId"
                            name="provenanceCountryId"
                            size="small"
                            variant="outlined"
                            clearOnBlur
                            selectOnFocus
                            forcePopupIcon
                            handleHomeEndKeys
                            disabled={disabled}
                            value={formStore.getProvenanceSelectedOption}
                            defaultValue={formStore.getProvenanceSelectedOption}
                            onChange={(e, value) => onChangeAutocomplete(value)}
                            options={formStore.countriesOptions}
                            getOptionLabel={(option) => option.name || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionDisabled={(option) => option.isDisabled}
                            renderOption={(props, option) => {
                                const { key, ...rest } = props;
                                return <li key={key} {...rest}>{option.name}</li>;
                            }}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    label={M.get('labels.countries')}
                                    required
                                    variant="outlined"
                                    error={!!errors.provenanceCountryId}
                                    helperText={!!errors.provenanceCountryId && M.get('form.errors.required')}
                                    inputProps={{ ...props.inputProps, maxLength: maxSymbols }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
            )}

            {formData.provenanceOption === 'custom' && (
                <Grid size={{ sm: 6 }}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            label="Custom"
                            required
                            name="provenanceCustomLabel"
                            error={!!errors.provenanceCustomLabel}
                            helperText={!!errors.provenanceCustomLabel && M.get('form.errors.required')}
                            value={formData.provenanceCustomLabel}
                            onChange={handleCustomInputChange}
                            placeholder="Eg. wine from the European Union"
                            disabled={disabled}
                        />
                    </FormControl>
                </Grid>
            )}
            <Grid size={{ xs: 12 }}>
                <Divider />
            </Grid>
        </>
    );
});

export default Provenance;
