import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import moment from 'moment';
import { MuiTelInput } from 'mui-tel-input';
import {
  Box,
  Paper,
  Button,
  FormControl,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Typography,
  Switch,
  Divider,
  Autocomplete,
  Tooltip,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import accountOptions from 'constants/sp-account-status-options';
import typeOptions from 'constants/sp-account-type-options';

import { routes } from 'config/index';
import { getMessage, isPositiveNumber, isNumeric } from 'helpers/helper';

import spStore from 'store/serviceProvidersStore';
import domainData from 'store/domainStore';

import ServiceProvidersService from 'services/serviceProvidersService';

import Loading from 'components/common/loading';
import ImageInput from 'components/common/form/imageInput';
import PageHeader from 'components/common/pageHeader';
import TagInput from 'components/common/tagInput';
import M from 'messages';
import formClasses from 'components/common/form/styles';
import classes from './styles';

const maxSymbols = 255;

const CreateSpWrapper = observer(({ isEdit, isCreate }) => {
  const currentSP = domainData.getCurrentSP();
  const isUserSuperAdmin = domainData.userIsSuperAdmin();

  return (
    <CreateServiceProvider
      isEdit={isEdit}
      isCreate={isCreate}
      currentSP={currentSP}
      isUserSuperAdmin={isUserSuperAdmin}
    />
  );
});

const CreateServiceProvider = observer(
  ({ isEdit = false, isCreate = false, currentSP, isUserSuperAdmin }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(!isCreate);
    const [currSpData, setCurrSpData] = useState(null);
    const [disableSave, setDisableSave] = useState(false);

    const data = spStore.getStoreSpForCreate(currentSP);
    const dataErrors = spStore.getSpDataErrors();

    const disabled = false;

    const setReadyData = (spData) => {
      const readyData = spStore.getAdaptedData(spData);
      spStore.setSpData(readyData);
      setCurrSpData(readyData);
      setLoading(false);
    };

    const getSP = useCallback(
      async () => {
        try {
          setLoading(true);
          const response = await ServiceProvidersService.getServiceProvider(id);
          if (response) {
            setReadyData(response.data);
          }
        } catch (err) {
          setLoading(false);
          enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [enqueueSnackbar]
    );

    const createSP = useCallback(
      async (newData) => {
        if (isUserSuperAdmin) {
          try {
            setDisableSave(true);
            const response = await ServiceProvidersService.createServiceProvider(newData);
            if (response) {
              enqueueSnackbar(getMessage(response.data), {
                variant: 'success',
              });
              domainData.loadInitialData();
              setDisableSave(false);
              const baseRoute = location.pathname.replace('/create', '');
              navigate(baseRoute);
            }
          } catch (err) {
            setDisableSave(false);
            setLoading(false);
            enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [enqueueSnackbar]
    );

    const updateSP = useCallback(
      async (newData) => {
        try {
          setDisableSave(true);
          const response = await ServiceProvidersService.updateServiceProvider(newData, id);
          if (response) {
            enqueueSnackbar(getMessage(response.data), { variant: 'success' });
            domainData.loadInitialData();
            setDisableSave(false);
            if (isUserSuperAdmin) {
              navigate(routes.serviceProviders.path, { replace: true });
            } else {
              navigate(`${location.pathname}`, { replace: true });
            }
          }
        } catch (err) {
          setDisableSave(false);
          setLoading(false);
          enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [enqueueSnackbar]
    );

    useEffect(() => {
      if (!isCreate && id) {
        getSP(id);
      }
      spStore.getCountriesData();
      return () => {
        spStore.clearSpDataErrors();
        spStore.clearCountriesData();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (event) => {
      event.preventDefault();
      const formHasError = spStore.spDataHasError(isCreate);
      if (!formHasError) {
        try {
          const formData = spStore.getSpData(isEdit);
          if (isEdit) {
            updateSP(formData);
          } else {
            createSP(formData);
          }
        } catch (err) {
          enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
            variant: 'error',
          });
        }
        return true;
      }
      enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
      return false;
    };

    const isFormChanged = () =>
      currSpData && data
        ? JSON.stringify(currSpData) !== JSON.stringify(data) || spStore.getCreateFormChanged()
        : false;

    const isSaveButtonDisabled = () =>
      isCreate
        ? disabled || disableSave
        : disabled || !isFormChanged() || disableSave;


    const handelCancelAction = () => {
      navigate(isUserSuperAdmin ? routes.serviceProviders.path : `${routes.serviceProviders.path}/${id}`);
    }

    const getEmailHelperText = () => {
      let helperText = '';
      if (dataErrors.contactPersonEmail && dataErrors.contactPersonEmail === 'required') {
        helperText = M.get('form.errors.required');
      } else if (dataErrors.contactPersonEmail && dataErrors.contactPersonEmail === 'invalidEmail') {
        helperText = M.get(`login.errors.invalidEmail`);
      }
      return helperText;
    }

    const getPhoneHelperText = () => {
      let helperText = '';
      if (dataErrors.contactPersonPhone && dataErrors.contactPersonPhone === 'required') {
        helperText = M.get('form.errors.required');
      } else if (dataErrors.contactPersonPhone && dataErrors.contactPersonPhone === 'invalidPhone') {
        helperText = M.get(`form.errors.invalidPhone`);
      }
      return helperText;
    }

    const getUrlFieldHelperText = (name) => {
      let helperText = '';
      if (dataErrors[name] && dataErrors[name] === 'required') {
        helperText = M.get('form.errors.required');
      } else if (dataErrors[name] && dataErrors[name] === 'invalidUrl') {
        helperText = M.get(`form.errors.invalidUrl`);
      }
      return helperText;
    }

    const getOptionLabel = (option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name;
    }

    if (loading || (data.id && !spStore.isCountryOptionLoaded)) {
      return <Loading />;
    }

    return (
      <Box sx={classes.page} >
        <PageHeader
          generalPageTitle={isUserSuperAdmin ? `${M.get('serviceProvider.pageTitle')} | ${isCreate ? M.get('actions.create') : data.name}` : M.get('serviceProvider.pageTitleSp')}
          onlyGeneralPageTitle
        />
        <Box sx={classes.pageContent}>
          <form onSubmit={onSubmit} noValidate>
            <Box component={Paper} sx={classes.boxContainer} pb={2} px={3} pt={3}>
              <Grid container spacing={3}>
                <Grid container size={{ xs: 12, sm: 6 }} spacing={3}>
                  <Grid size={{ xs: 12, sm: 12 }}>
                    <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                      <TextField
                        label={`${M.get('serviceProvider.name')}`}
                        id="name"
                        name="name"
                        value={data.name}
                        disabled={disabled}
                        required
                        onChange={(e) => spStore.onChangeField(e, isCreate)}
                        variant="outlined"
                        error={!!dataErrors.name}
                        inputProps={{ maxLength: maxSymbols }}
                        FormHelperTextProps={{
                          sx: classes.helperDiv,
                        }}
                        helperText={
                          dataErrors.name ?
                            M.get('form.errors.required') :
                            <Box component='span' sx={classes.helperDiv}>
                              <Box component='span'>{data.name?.length || 0}/{maxSymbols}</Box>
                            </Box>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12 }}>
                    <FormControl fullWidth sx={classes.formControl}>
                      <InputLabel id="type-select-select-label">{`${M.get('serviceProvider.type.title')} *`}</InputLabel>
                      <Select
                        sx={{
                          ...(dataErrors.type &&
                            formClasses.invalidSelectContainer),
                        }}
                        disabled={disabled}
                        value={data.type}
                        onChange={(e) => spStore.onChangeField(e, isCreate)}
                        id="type-select"
                        name="type"
                        label={M.get('serviceProvider.type.title')}
                        error={!!dataErrors.type}
                        required
                      >
                        {typeOptions.map((opt) => (
                          <MenuItem key={opt.id} value={opt.value}>
                            {opt.title}
                          </MenuItem>
                        ))}
                      </Select>
                      {dataErrors.type && <FormHelperText sx={formClasses.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                    <TextField
                      label={`${M.get('serviceProvider.description')}`}
                      id="description"
                      name="description"
                      value={data.description}
                      disabled={disabled}
                      onChange={(e) => spStore.onChangeField(e, isCreate)}
                      multiline
                      rows="6"
                      size="medium"
                      required
                      error={!!dataErrors.description}
                      helperText={dataErrors.description && M.get('form.errors.required')}
                      InputProps={{
                        sx: { position: 'relative', paddingRight: '40px' },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              position: 'absolute',
                              top: '25px',
                              right: '12px',
                            }}
                          >
                            <Tooltip placement="top" title={M.get('serviceProvider.descriptionTooltip')}>
                              <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl} >
                    <Autocomplete
                      id="countryId"
                      variant="outlined"
                      freeSolo
                      clearOnBlur
                      selectOnFocus
                      forcePopupIcon
                      handleHomeEndKeys
                      value={spStore.selectedCountry}
                      onChange={(e, value) => spStore.onChangeAutocomplete(value, 'countryId', 'countries')}
                      disabled={disabled}
                      options={spStore.countriesOptions}
                      getOptionLabel={getOptionLabel}
                      getOptionDisabled={(option) => option.isDisabled}
                      renderOption={(props, option) => (<li key={option.id} {...props}>{option.name}</li>)}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          required
                          label={`${M.get('serviceProvider.country')}`}
                          variant="outlined"
                          error={!!dataErrors.countryId}
                          helperText={(!!dataErrors.countryId) && M.get('form.errors.required')}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <Grid size={{ xs: 12, sm: 12 }}>
                    <TagInput
                      dataErrors={dataErrors}
                      disabled={disabled}
                      onChange={spStore.setTags}
                      value={spStore.getTags()}
                      options={[]}
                      fieldName='partnerFeatureTags'
                      placeholder={M.get('serviceProvider.partnerFeatureTags')}
                      tooltip={M.get('serviceProvider.cuisinesTooltip')}
                    />
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl} >
                    <TextField
                      required
                      id="region"
                      name="region"
                      disabled={disabled}
                      value={data.region || ''}
                      onChange={(e) => spStore.onChangeField(e, isCreate)}
                      label={`${M.get('serviceProvider.region')}`}
                      error={!!dataErrors.region}
                      inputProps={{ maxLength: maxSymbols }}
                      helperText={
                        dataErrors.region ?
                          M.get('form.errors.required') :
                          <Box component='span' sx={classes.helperDiv}>
                            <Box component='span'>{data.region?.length || 0}/{maxSymbols}</Box>
                          </Box>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                    <TextField
                      label={M.get('serviceProvider.website')}
                      id="website"
                      name="website"
                      value={data.website || ''}
                      disabled={disabled}
                      onChange={(e) => spStore.onChangeUrlField(e, isCreate)}
                      inputProps={{ maxLength: maxSymbols }}
                      error={!!dataErrors.website}
                      helperText={
                        dataErrors.website ?
                          getUrlFieldHelperText('website') :
                          <Box component='span' sx={classes.helperDiv}>
                            <Box component='span'>{data.website?.length || 0}/{maxSymbols}</Box>
                          </Box>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                    <TextField
                      label={`${M.get('serviceProvider.fullAddress')}`}
                      id="fullAddress"
                      name="fullAddress"
                      value={data.fullAddress}
                      disabled={disabled}
                      onChange={(e) => spStore.onChangeField(e, isCreate)}
                      required
                      error={!!dataErrors.fullAddress}
                      helperText={
                        dataErrors.fullAddress ?
                          M.get('form.errors.required') :
                          <Box component='span' sx={classes.helperDiv}>
                            <Box component='span'>{data.fullAddress?.length || 0}/{maxSymbols}</Box>
                          </Box>
                      }
                      InputProps={{
                        inputProps: { maxLength: maxSymbols },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip placement="top" title={M.get('serviceProvider.fullAddressTooltip')}>
                              <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid >
                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                    <TextField
                      label={M.get('serviceProvider.reservation')}
                      id="reservation"
                      name="reservation"
                      value={data.reservation || ''}
                      disabled={disabled}
                      onChange={(e) => spStore.onChangeUrlField(e, isCreate)}
                      error={!!dataErrors.reservation}
                      helperText={
                        dataErrors.reservation ?
                          getUrlFieldHelperText('reservation') :
                          <Box component='span' sx={classes.helperDiv}>
                            <Box component='span'>{data.reservation?.length || 0}/{maxSymbols}</Box>
                          </Box>
                      }
                      InputProps={{
                        inputProps: { maxLength: maxSymbols },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip placement="bottom" title={M.get('serviceProvider.reservationTooltip')}>
                              <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid >
                <Grid size={{ xs: 12 }}>
                  <Box sx={classes.fieldHeader}>
                    <Typography sx={classes.fieldHeaderText}>{`${M.get('serviceProvider.logoFormat')} *`}</Typography>
                  </Box>
                  <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                    <ImageInput
                      onChange={(e) => spStore.onChangeField({ target: { value: e, name: 'logo' } })}
                      defaultValue={data.logo}
                      disabled={disabled}
                      setDisableSave={setDisableSave}
                      errorStyle={!!dataErrors.logo}
                    />
                    {dataErrors.logo && <FormHelperText sx={formClasses.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
                  </FormControl>
                </Grid>
                {isUserSuperAdmin && <>
                  <Grid size={{ xs: 12 }}>
                    <Divider sx={classes.divider} />
                    <Typography color="textPrimary" sx={classes.contactPerson}> {M.get('serviceProvider.adminSection')} </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth sx={classes.formControl}>
                      <InputLabel id="accountStatus-select-label">{M.get('serviceProvider.accountStatus.title')}</InputLabel>
                      <Select
                        sx={{
                          ...(dataErrors.accountStatus &&
                            formClasses.invalidSelectContainer),
                        }}
                        disabled={disabled}
                        value={data.accountStatus}
                        onChange={(e) => spStore.onChangeField(e, isCreate)}
                        id="accountStatus-select"
                        name="accountStatus"
                        label={M.get('serviceProvider.accountStatus.title')}
                        error={!!dataErrors.type}
                        required
                      >
                        {accountOptions.map((opt) => (
                          <MenuItem key={opt.id} value={opt.value}>
                            {opt.title}
                          </MenuItem>
                        ))}
                      </Select>
                      {dataErrors.accountStatus && <FormHelperText sx={formClasses.errorHelperText}> {M.get('form.errors.required')} </FormHelperText>}
                    </FormControl>
                  </Grid >
                  {!isCreate && <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                      <TextField
                        label={M.get('serviceProvider.creationDate')}
                        id="createdAt"
                        name="createdAt"
                        value={data.created_at ? moment(data.created_at).format('D-MMM-YY') : ''}
                        disabled
                      />
                    </FormControl>
                  </Grid >}
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                      <TextField
                        type="text"
                        id="sortingOrder"
                        name="sortingOrder"
                        label={M.get('serviceProvider.order')}
                        disabled={disabled}
                        value={data.sortingOrder}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          if ((isNumeric(value) && isPositiveNumber(value)) || value === '') {
                            spStore.onChangeField(e, isCreate);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid >
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControlLabel
                      variant="outlined"
                      label={<Typography sx={classes.fieldHeaderText}> {M.get('serviceProvider.isQrPartner')} </Typography>}
                      labelPlacement="start"
                      control={
                        <Switch
                          id="isQrPartner"
                          color="primary"
                          name="isQrPartner"
                          onChange={spStore.onChangeCheckBox}
                          checked={!!data.isQrPartner}
                          disabled={disabled}
                        />
                      }
                      sx={classes.isQrPartner}
                    />
                  </Grid >
                </>
                }
                {isCreate && <>
                  <Grid size={{ xs: 12 }}>
                    <Divider sx={classes.divider} />
                    <Typography color="textPrimary" sx={classes.contactPerson}> {M.get('serviceProvider.contactPerson')} </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                      <TextField
                        label={`${M.get('serviceProvider.contactPersonName')}`}
                        id="contactPersonName"
                        name="contactPersonName"
                        value={data.contactPersonName}
                        disabled={disabled}
                        onChange={(e) => spStore.onChangeField(e, isCreate)}
                        required
                        error={!!dataErrors.contactPersonName}
                        inputProps={{ maxLength: maxSymbols }}
                        helperText={
                          dataErrors.contactPersonName ?
                            M.get('form.errors.required') :
                            <Box component='span' sx={classes.helperDiv}>
                              <Box component='span'>{data.contactPersonName?.length || 0}/{maxSymbols}</Box>
                            </Box>
                        }
                      />
                    </FormControl>
                  </Grid >
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                      <TextField
                        label={`${M.get('serviceProvider.contactPersonEmail')}`}
                        id="contactPersonEmail"
                        name="contactPersonEmail"
                        value={data.contactPersonEmail}
                        disabled={disabled}
                        onChange={(e) => spStore.onChangeEmailField(e, isCreate)}
                        required
                        error={!!dataErrors.contactPersonEmail}
                        helperText={getEmailHelperText()}
                      />
                    </FormControl>
                  </Grid >
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormControl fullWidth variant="outlined" sx={classes.formControl}>
                      <MuiTelInput
                        id="contactPersonPhone"
                        name="contactPersonPhone"
                        value={data.contactPersonPhone}
                        disabled={disabled}
                        defaultCountry='US'
                        error={!!dataErrors.contactPersonPhone}
                        onChange={(value) => spStore.onChangePhoneField(value, isCreate)}
                        helperText={getPhoneHelperText()}
                        label={M.get('serviceProvider.contactPersonPhone')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip placement="top" title={M.get('serviceProvider.phoneTooltip')}>
                                <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid >
                </>}
              </Grid >
            </Box >
          </form >
        </Box >
        <Box sx={classes.pageFooter}>
          <Box>
            {!disabled && <Grid container sx={classes.footerPart}>
              <Button
                variant="text"
                color="primary"
                onClick={handelCancelAction}
              >
                {M.get('actions.discard')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaveButtonDisabled()}
                onClick={onSubmit}
              >
                {isCreate ? M.get('form.create') : M.get('form.save')}
              </Button>
            </Grid>}
          </Box>
        </Box>
      </Box >
    );
  }
);

CreateServiceProvider.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
};

export default CreateSpWrapper;