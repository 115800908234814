import {variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    display: 'flex',
  },
  sidebarContainer: {
    backgroundColor: theme.palette.primary.backgroundColor,
    overflowX: 'hidden',
    boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.05)'
  },
  noBorder: {
    border: 'none',
    backgroundColor: theme.palette.primary.colorDark,
  },
  drawerContainer: {
    overflow: 'auto',
    overflowX: 'hidden',
    height: '100%',

    // NOTE: custom scroll bar (not worked for mozila)
    '&::-webkit-scrollbar': {
      width: '4px'
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 5px ${theme.palette.primary.scrollColor}`,
      borderRadius: '10px'
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.scrollColor,
      borderRadius: '10px'
    },

  },
  drawer: {
    zIndex: 50,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: variables.drawerRootWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.sideBarBgColor,
  },
  drawerClose: {
    display: 'none',
  },
  drawerList: {
    padding: 0
  },
  logoContainer: {
    height: 'auto',
    padding: '30px',
    marginTop: '-16px',
  },
  logoContainerClosedMode: {
    padding: '0',
    marginTop: '0',
  },
  logo: {
    width: 'auto',
    height: 'auto',
    maxWidth: '180px'
  },
  toolBar: {
    minHeight: variables.headerHeight
  },
  mobileDrawer: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  }
};