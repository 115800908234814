import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Box, ListItem, ListItemText, ListItemButton, Tooltip, Icon, useMediaQuery, Collapse, List, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

import M from 'messages';
import domainData from 'store/domainStore';

import classes from './styles';

const SideBarLink = observer(({ sidebarStore, item, link: linkWithParameters, icon = null, label, containerClassName = '', iconPosition = '', height = 48, isLastElement = false, subMenu, isSubMenu = false }) => {
  const downSM = useMediaQuery('(max-width:980px)');
  const location = useLocation();

  const currentSpData = domainData.getCurrentSpData();
  const isUserSuperAdmin = domainData.userIsSuperAdmin();
  const isAdminOrHigher = domainData.userIsSuperAdminOrPartner();
  const currentUser = domainData.getCurrentUser();

  useEffect(() => {
    runInAction(() => {
      sidebarStore.clickedSideBarLink(location.pathname);
    });
  }, [location.pathname, sidebarStore]);

  const link = typeof linkWithParameters === 'function' ? linkWithParameters({ isUserSuperAdmin, domainData }) : linkWithParameters;
  const storedActiveLink = sidebarStore.activeSideBarLink;
  const isLinkActive = link && storedActiveLink ? storedActiveLink.includes(link) : location.pathname.includes(link);
  const openDrawer = sidebarStore.sidebarState;

  const toggleCollapse = () => {
    runInAction(() => {
      sidebarStore.clickedSideBarLink(link);
    });
    if (link && downSM) {
      sidebarStore.toggleSidebarByValue(false);
    }
  };

  const hasSubMenu = () => {
    let check = false;
    subMenu?.forEach((i) => {
      if (i.roles.includes(currentUser?.usersRole.name)) {
        check = true;
      }
    })
    return check
  };

  return (
    <Tooltip title={item.isParent || openDrawer ? '': label} aria-label={item.label} placement="right">
      <Box sx={{
        ...classes[containerClassName],
        ...(!isSubMenu && isLastElement && classes.lastElement),
      }} >
        {subMenu
          ? <Typography
            sx={{
              ...classes.listItemButton,
              ...classes.subMenuItem
            }}>{label}</Typography>
          : <ListItem
            component={link && Link}
            sx={{
              ...classes.listItem,
              ...(!openDrawer && classes.listItemClosedDrawer),
            }}
            onClick={toggleCollapse}
            to={link}
            style={{
              height,
              display: !item.show ? true : item.show({isSidebarOpened: openDrawer, isUserSuperAdmin, isAdminOrHigher, currentSpData }) ? true : 'none',
            }}
          >
            <ListItemButton
              disabled={item.disable ? item.disable() : false}
              sx={classes.listItemButton}
              disableRipple
            >
              <div style={{
                ...classes.listItemContainer,
              }}>
                {icon && iconPosition === 'start' && (
                  <Icon
                    sx={{
                      ...(openDrawer ? classes.listItemIcon : classes.listItemIconClosedDrawer),
                      ...(isLinkActive && classes.iconActive),
                      ...(item.hideOpacity && classes.hideOpacity)
                    }}
                  >
                    {icon}
                  </Icon>
                )}
                <ListItemText
                  sx={{
                    ...classes.listItemText,
                    ...(isLinkActive && classes.linkActive),
                    ...(subMenu && hasSubMenu() && classes.listItemBold),
                  }}
                  primary={label}
                />
              </div>
            </ListItemButton>
          </ListItem>
        }
        {subMenu && hasSubMenu() && (
          <Collapse
            in
            unmountOnExit
          >
            <List component='div' disablePadding sx = {classes.listItemSubMenu}>
              {subMenu.map(subMenuItem => subMenuItem.roles.includes(currentUser?.usersRole.name) && (
                <div key={subMenuItem.id}>
                  <SideBarLink
                    item={subMenuItem}
                    icon={subMenuItem.icon}
                    link={subMenuItem.link}
                    label={M.get(subMenuItem.label)}
                    openDrawer={openDrawer}
                    subMenu={subMenuItem.subMenu}
                    iconPosition={subMenuItem.iconPosition}
                    height={subMenuItem.height}
                    sidebarStore={sidebarStore}
                    isSubMenu
                  />
                </div>
              ))}
            </List>
          </Collapse>
        )}
      </Box>
    </Tooltip>
  );
})

SideBarLink.propTypes = {
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  iconPosition: PropTypes.string,
  height: PropTypes.number,
  item: PropTypes.instanceOf(Object),
  isLastElement: PropTypes.bool,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    })
  ),
  isSubMenu: PropTypes.bool,
};

export default SideBarLink;