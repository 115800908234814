import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ButtonsGroup from 'components/common/customBtnGroup';

const Alcoholization = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const { formData, errors } = formStore;

    const handleOptionChange = (name, value) => {
        const e = { target: { name, value } };
        handleChange(e);
        if (value === '') {
            const clearCustomLabelEvent = { target: { name: 'dateOfMinimumDurability', value: '' } };
            handleChange(clearCustomLabelEvent); // Clear date picker when a new option is selected
        }
    };

    const handleCustomInputChange = (newDate) => {
        const formattedDate = newDate.format('DD MMM YYYY');
        const e = { target: { name: 'dateOfMinimumDurability', value: formattedDate } };
        handleChange(e);
    };

    const configOptions = {
        label: 'De-alcoholization',
        name: 'alcoholization',
        fields: [
            { value: 'De-alcoholized', label: 'De-alcoholized' },
            { value: 'Partially de-alchohized', label: 'Partially de-alchohized' },
        ],
    };

    return (
        <Grid size={{ xs: 12, sm: 12 }} mb={2}>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                    <Box display="flex" alignItems="center">
                        <Typography sx={classes.fieldHeaderText} component='h1'>
                            {M.get('labels.deAlcoholization')}
                        </Typography>
                        <Tooltip
                            title={
                                <Typography
                                    sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                >
                                    Indicates whether the wine has had its alcohol content partially or fully removed. This process can create low-alcohol or alcohol-free wines.
                                    &quot;Date of minimum durability&quot; is the date until which the wine retains its specific properties when properly stored.
                                </Typography>
                            }
                            placement='top'
                        >
                            <HelpOutlineIcon sx={classes.helpIcon} />
                        </Tooltip>
                    </Box>
                    <ButtonsGroup
                        disabled={disabled}
                        groupObject={configOptions}
                        handleChange={handleOptionChange}
                        value={formData.alcoholization}
                        noTranslation
                    />
                </Grid>
                {formData.alcoholization !== '' && (
                    <Grid size={{ xs: 6 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                size="small"
                                label={`${M.get('labels.dateOfMinimumDurability')} *`}
                                name="dateOfMinimumDurability"
                                value={dayjs(formData.dateOfMinimumDurability)}
                                onChange={handleCustomInputChange}
                                required
                                placeholder="mm/dd/yyyy"
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true,
                                        variant: 'outlined',
                                        error: !!errors.dateOfMinimumDurability,
                                        helperText: errors.dateOfMinimumDurability || null,
                                        InputProps: {
                                            sx: { fontSize: '1rem' }, // Customize font size
                                        },
                                        InputLabelProps: {
                                            sx: { fontSize: '1rem' }, // Customize label font size
                                        },
                                    },
                                }}
                                disabled={disabled}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
});

export default Alcoholization;
