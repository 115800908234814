import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';

// private route
import PrivateRoute from 'routes/PrivateRoute';

// pages
import ServiceProviderCreate from 'pages/serviceProviders/create';
import ServiceProviders from 'pages/serviceProviders';

import KiosksMenu from 'pages/kiosksMenu';
import KiosksMenuCreate from 'pages/kiosksMenu/create';
import Catalogue from 'pages/catalogue';
import MenuItemCreate from 'pages/catalogue/create';
import WineLabels from 'pages/wineLabels';
import WineLabelsCreate from 'pages/wineLabels/create';

import Users from 'pages/users/index';

import Settings from 'pages/settings';
import Login from 'pages/login';
import Signup from 'pages/signup';
import Profile from 'pages/profile';
import Dashboard from 'pages/dashboard/index';
import NotFound from 'pages/notFound';
import Home from 'pages/welcome';

import ForgotPassword from 'pages/forgotPassword';
import ResetPassword from 'pages/resetPassword';
import ChangePassword from 'pages/changePassword';

import SubscriptionResponse from 'pages/subscriptions/subscriptionResponse';
import Subscriptions from 'pages/subscriptions';

import { setupInterceptors } from 'services/client/axiosHelper';
import domainData from 'store/domainStore';
import { isSuperAdmin } from 'helpers/usersRolesHelper';
import Loading from 'components/common/loading';

import { routes } from './config/index';
import './App.css';

const useStyles = () => ({
  root: {
    display: 'block'
  }
});

const App = observer(() => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  setupInterceptors(location, navigate);
  const currentUser = domainData.getCurrentUser();
  const isUserSuperAdmin = isSuperAdmin(currentUser);
  useEffect(() => {
    if (currentUser?.id) {
      domainData.checkSubscriptionAccess(isUserSuperAdmin);
    }
  }, [currentUser?.id, isUserSuperAdmin]);
  if (domainData.getSubscriptionAccessLoading()) {
    return <Loading />;
  }
  return (
    <div style={classes.root} >
      <Routes>
        <Route path={routes.login.path} exact element={<Login />} />
        <Route path={routes.signUp.path} exact element={<Signup />} />
        <Route path={routes.forgotPassword.path} exact element={<ForgotPassword />} />
        <Route path={routes.resetPassword.path} exact element={<ResetPassword />} />
        <Route path={routes.inviteUser.path} exact element={<ResetPassword />} />
        <Route path={routes.changePassword.path} exact element={<ChangePassword />} />
        <Route exact path={routes.home.path}
          element={<Navigate to={routes.welcome.path} />}
        />
        <Route exact path={routes.welcome.path} element={<PrivateRoute component={Home} path={routes.welcome.path} />} />
        {currentUser?.serviceProviderId && <Route exact path={routes.serviceProviderCreate.path}
          element={<PrivateRoute component={ServiceProviderCreate} to={routes.serviceProviderEdit.path.replace(':id', currentUser.serviceProviderId)} />}
        />}
        <Route index exact path={routes.settings.path} element={<PrivateRoute component={Settings} path={routes.settings.path} />} />
        <Route index exact path={routes.dashboard.path} element={<PrivateRoute component={Dashboard} path={routes.dashboard.path} />} />
        {isUserSuperAdmin &&
          <Route exact path={routes.users.path} element={<PrivateRoute component={Users} path={routes.users.path} />} />
        }
        <Route exact path={routes.userEdit.path} element={<PrivateRoute component={Profile} path={routes.userEdit.path} isEdit />} />
        <Route exact path={routes.profileEdit.path} element={<PrivateRoute component={Profile} path={routes.profileEdit.path} isProfile />} />
        <Route exact path={routes.serviceProviderCreate.path} element={<PrivateRoute component={ServiceProviderCreate} path={routes.serviceProviderCreate.path} isCreate />} />
        <Route exact path={routes.serviceProviderEdit.path} element={<PrivateRoute component={ServiceProviderCreate} path={routes.serviceProviderEdit.path} isEdit />} />
        <Route exact path={routes.serviceProviders.path} element={<PrivateRoute component={ServiceProviders} path={routes.serviceProviders.path} />} />

        <Route exact path={routes.kiosksMenusCreate.path} element={<PrivateRoute component={KiosksMenuCreate} path={routes.kiosksMenusCreate.path} isCreate />} />
        <Route exact path={routes.kiosksMenusEdit.path} element={<PrivateRoute component={KiosksMenuCreate} path={routes.kiosksMenusEdit.path} isEdit />} />
        <Route exact path={routes.kiosksMenus.path} element={<PrivateRoute component={KiosksMenu} path={routes.kiosksMenus.path} />} />

        <Route exact path={routes.catalogueCreate.path} element={<PrivateRoute component={MenuItemCreate} path={routes.catalogueCreate.path} isCreate />} />
        <Route exact path={routes.catalogueEdit.path} element={<PrivateRoute component={MenuItemCreate} path={routes.catalogueEdit.path} isEdit />} />
        <Route exact path={routes.catalogue.path} element={<PrivateRoute component={Catalogue} path={routes.catalogue.path} />} />

        <Route exact path={routes.wineLabelsCreate.path} element={<PrivateRoute component={WineLabelsCreate} path={routes.wineLabelsCreate.path} isCreate />} />
        <Route exact path={routes.wineLabelsEdit.path} element={<PrivateRoute component={WineLabelsCreate} path={routes.wineLabelsEdit.path} isEdit />} />
        <Route exact path={routes.wineLabels.path} element={<PrivateRoute component={WineLabels} path={routes.wineLabels.path} />} />

        <Route exact path={routes.subscriptions.path} element={<PrivateRoute component={Subscriptions} path={routes.subscriptions.path} />} />
        <Route exact path={routes.subscription.path} element={<PrivateRoute component={SubscriptionResponse} path={routes.subscription.path} />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
});

export default App;