import React, { useState, useEffect } from 'react';
// Modules
import { observer } from 'mobx-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { passwordStrength } from 'check-password-strength'
import qs from 'qs';
// MUI Core
import {
  Button,
  Paper,
  Box,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Loading from 'components/common/loading';
import PasswordValidation from 'components/common/passwordValidation';
import PageHeader from 'components/common/pageHeader';
import withAuthHOC from 'components/common/authHOC/authHOC';

import changePasswordStore from 'store/changePasswordStore';
import AuthService from 'services/authService';
import { getMessage } from 'helpers/helper';
import { ChangePasswordForm } from 'config/forms';
import { routes } from 'config';
import M from 'messages';

import classes from './styles';

const ChangePassword = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const dataErrors = changePasswordStore.getChangePasswordDataErrors();
  const data = changePasswordStore.getChangePasswordData();
  const requirements = passwordStrength(data.newPassword);
  const [pageLoading, setPageLoading] = useState(true);

  const [loading, setLoading] = useState(false);
  const [passwordLength, setPasswordLength] = useState(10);

  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const allowSubmit = requirements.length >= passwordLength && requirements.contains.includes('uppercase') && requirements.contains.includes('lowercase') && requirements.contains.includes('number') && requirements.contains.includes('symbol');

  useEffect(() => {
    try {
      setPageLoading(true);
      if (pageParams.passwordLength && pageParams.email) {
        setPasswordLength(pageParams.passwordLength);
        setPageLoading(false);
      }
    } catch (err) {
      setPageLoading(false);
    }

    return () => {
      changePasswordStore.clearData();
      changePasswordStore.clearDataErrors();
    }
  }, [pageParams.email, pageParams.passwordLength])

  const getRequiredFields = () => {
    const requiredList = [];
    ChangePasswordForm.forEach((i) => {
      requiredList.push(i.name);
    });
    return requiredList;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formHasError = changePasswordStore.changePasswordDataHasError(getRequiredFields());
    if (!formHasError) {
      try {
        const res = await AuthService.changePassword({ ...data, email: pageParams.email });
        enqueueSnackbar(getMessage(res.data), { variant: 'success' });
        setLoading(false);
        navigate(routes.login.path);
      } catch (err) {
        setLoading(false);
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), { variant: 'error' });
      }
      return true;
    }
    setLoading(false);
    enqueueSnackbar(M.get('form.errors.notValidForm'), { variant: 'error' });
    return false;
  };

  const passwordHasError = () => {
    if (requirements.length < passwordLength) {
      return true;
    }
    if (!requirements.contains.includes('lowercase') || !requirements.contains.includes('uppercase') || !requirements.contains.includes('number') || !requirements.contains.includes('symbol')) {
      return true;
    }
    return false;
  }

  const showOldPassword = changePasswordStore.getShowOldPasswordState();
  const showNewPassword = changePasswordStore.getShowNewPasswordState();
  const showRepeatPassword = changePasswordStore.getShowRepeatPasswordState();

  const repeatNewPasswordHasError = () => changePasswordStore.getChangeRepeatPSWChanged() && (!!dataErrors.repeatNewPassword || !changePasswordStore.validateChangePasswords());
  const getRepeatNewPasswordTxt = () =>
    dataErrors.repeatNewPassword
      ? M.get('form.errors.required') : !changePasswordStore.validateChangePasswords() && changePasswordStore.getChangeRepeatPSWChanged()
        ? M.get('passwordValidation.passwordsNotMatch') : '';

  if (pageLoading) return <Loading customClass={classes.authLoading} />;

  return (
    <Box sx={classes.container} component={Paper} width={375} margin='auto' elevation={3}>
      <PageHeader
        generalPageTitle={M.get('changePassword.title')}
        onlyGeneralPageTitle
      />
      <Typography component='h3' sx={classes.title}> {M.get('changePassword.title')} </Typography>
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormControl variant="standard">
                  <TextField
                    variant="outlined"
                    label={M.get("passwordValidation.oldPassword")}
                    id="oldPassword"
                    name="oldPassword"
                    value={data.oldPassword}
                    required
                    onChange={changePasswordStore.onChangeFieldRPW}
                    error={changePasswordStore.getChangeOldPSWChanged() && (!!dataErrors.oldPassword)}
                    helperText={dataErrors.oldPassword ? M.get("form.errors.required") : ''}
                    type={showOldPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{ root: classes.eyeIcon }}
                            onClick={() => changePasswordStore.setEyeIconStateByField('showOldPassword')}
                            edge="end"
                            size="large">
                            {showOldPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormControl variant="standard">
                  <TextField
                    variant="outlined"
                    label={M.get("passwordValidation.newPassword")}
                    id="newPassword"
                    name="newPassword"
                    value={data.newPassword}
                    required
                    onChange={changePasswordStore.onChangeFieldRPW}
                    error={changePasswordStore.getChangeNewPSWChanged() && passwordHasError()}
                    helperText={dataErrors.newPassword ? M.get("form.errors.required") : ''}
                    type={showNewPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{ root: classes.eyeIcon }}
                            onClick={() => changePasswordStore.setEyeIconStateByField('showNewPassword')}
                            edge="end"
                            size="large">
                            {showNewPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 12 }}>
                <FormControl variant="filled">
                  <TextField
                    variant="outlined"
                    label={M.get("passwordValidation.repeatNewPassword")}
                    id="repeatNewPassword"
                    name="repeatNewPassword"
                    value={data.repeatNewPassword}
                    required
                    onChange={changePasswordStore.onChangeFieldRPW}
                    error={repeatNewPasswordHasError()}
                    helperText={getRepeatNewPasswordTxt()}
                    type={showRepeatPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{ root: classes.eyeIcon }}
                            onClick={() => changePasswordStore.setEyeIconStateByField('showRepeatPassword')}
                            edge="end"
                            size="large">
                            {showRepeatPassword ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }} container justifyContent="left" sx={classes.requirementContainer}>
            <Grid size={{ xs: 12 }}>
              <PasswordValidation requirements={requirements} passwordLength={passwordLength} />
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              sx={(!changePasswordStore.isSubmitBtnActive() || !allowSubmit || loading) ? classes.submitDisabled : classes.submit}
              disabled={!changePasswordStore.isSubmitBtnActive() || !allowSubmit || loading}
            >
              {loading ? <CircularProgress color="inherit" size={24} /> : M.get('actions.save')}
            </Button>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <FormControl fullWidth variant="filled">
              <Link sx={classes.forgotPasswordLink} id="forgotPassword" to={routes.forgotPassword.path}>
                {M.get('pages.forgotPasswordText')}
              </Link>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

export default withAuthHOC(ChangePassword);