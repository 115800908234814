import React from 'react';
import { observer } from 'mobx-react';
import { TextField, InputAdornment, Tooltip, Box, Typography, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImageInput from 'components/common/form/imageInput';

const ProducerInfo = observer(({ formStore, handleChange, classes, M, disabled = false }) => {
    const maxSymbols = 255;
    const { formData, errors } = formStore;

    return (
        <>
            <Grid size={{ xs: 12, sm: 6 }}>
                <Box>
                    <Typography sx={classes.fieldHeaderText}>Producer logo</Typography>
                </Box>
                <FormControl fullWidth variant="filled">
                    <ImageInput
                        onChange={(e) => handleChange({ target: { value: e, name: 'producerImage' } })}
                        defaultValue={formData.producerImage}
                        errorStyle={!!errors.producerImage}
                        smallPreview
                        disabled={disabled}
                    />
                </FormControl>
            </Grid >
            <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                    size="small"
                    fullWidth
                    id="producer"
                    label={M.get('labels.producer')}
                    name="producer"
                    value={formData.producer}
                    required
                    onChange={handleChange}
                    variant="outlined"
                    error={!!errors.producer}
                    helperText={
                        errors.producer ? errors.producer : null
                    }
                    disabled={disabled}
                    InputProps={{
                        inputProps: { maxLength: maxSymbols },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip
                                    placement="top"
                                    title={
                                        <>
                                            <Typography
                                                sx={{ fontSize: '11px', lineHeight: 'normal', color: 'inherit' }}
                                            >
                                                <b>Article 46 of Regulation (EU) 2019/33</b>
                                            </Typography>
                                        </>
                                    }
                                >
                                    <HelpOutlineIcon sx={classes.fieldHeaderIcon} />
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid >
        </>
    );
});

export default ProducerInfo;
