import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  FormControl,
  Fab,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import cataloguesStore from 'store/catalogueStore';

import M from 'messages';
import classes from './styles';

const Ratings = observer(({ data, disabled }) => {
  const hasAllData = !data.length || (data.length && data.every((item) => item.ratingTypeId && item.rating));
  return (
    <Box sx={classes.root} mt={1}>
      {data.map((item, index) => (
        <Grid container spacing={3} key={item.id}>
          <Grid size={{ xs: 7 }} mb={2} >
            <FormControl fullWidth>
              <InputLabel id="rating-select-label" sx={{ ...classes.placeholderColor }} >
                {M.get('menuItem.ratingFieldName')}
              </InputLabel>
              <Select
                variant="outlined"
                id="ratingTypeId"
                name="ratingTypeId"
                value={item?.ratingTypeId}
                animation="false"
                labelId='rating-select-label'
                label={M.get('menuItem.ratingFieldName')}
                disabled={disabled}
                onChange={(event) =>
                  cataloguesStore.onChangeRating(event, item, index)
                }
                MenuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                }}
              >
                {cataloguesStore.getRatingsOptions.map((rating) => {
                  const isHasOption = data?.find((storeData) => storeData.ratingTypeId === rating.id);
                  return (
                    <MenuItem
                      key={rating.id}
                      value={rating.id}
                      disabled={!!isHasOption}
                    >
                      {rating.name} - {rating.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 3 }} mb={2}>
            <FormControl fullWidth >
              <TextField
                id="rating"
                name="rating"
                type="text"
                variant="outlined"
                autoComplete="off"
                label={M.get('menuItem.rating')}
                value={item.rating}
                disabled={disabled}
                onChange={(event) => {
                  cataloguesStore.onChangeRating(event, item, index);
                }}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 2 }} mb={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <Fab
              color="primary"
              onClick={() => cataloguesStore.handleRemoveRatingRow(index)}
              disabled={disabled}
              sx={classes.deleteIcon}
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        </Grid>
      ))}
      <Grid sx={classes.addIconContainer}>
        <Fab
          color="primary"
          disabled={disabled || !hasAllData}
          onClick={cataloguesStore.handleAddRatingRow}
          sx={classes.addIcon}
        >
          <AddIcon />
        </Fab>
      </Grid>
    </Box>
  )
});

Ratings.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default Ratings;
