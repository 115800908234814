import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    minHeight: `calc(100vh - ${variables.headerHeight} - ${variables.footerHeight} - 40px)`,
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    padding: '12px 24px 24px 24px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal'
  },
  title: {
    fontSize: '40px',
    fontWeight: '600',
    textAlign: 'center',
  },
  description: {
    color: '#3D3D3D',
  },
  wineManagement: {
    fontSize: '24px',
    fontWeight: '500',
  },
  card: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgb(0 0 0 / 5%)'
  },
  boldText: {
    fontWeight: '600',
    color: '#1A5787'
  },
  listItem: {
    marginTop: '8px',
    lineHeight: '24px'
  },
  note: {
    fontStyle: 'italic',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}
