
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { routes } from 'config';
import M from 'messages';

import classes from './styles';

const NoAccess = ({ type = 'standard' }) => (
  <Typography sx={classes.noAccess}>
    {M.get('subscriptions.noAccess1')}
    &nbsp;
    <a style={classes.link} href={`${routes.subscriptions.path}?type=${type}`} rel="noopener noreferrer">{M.get('subscriptions.upgrade')}</a>
    &nbsp;
    {M.get('subscriptions.noAccess2')}
  </Typography>
);

NoAccess.propTypes = {
  type: PropTypes.string,
};

export default NoAccess;