import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress, Alert, Typography, Tabs, Tab } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TabContext, TabPanel } from '@mui/lab';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import subscriptionsStore from 'store/subscriptionsStore';
import domainData from 'store/domainStore';
import SubscriptionService from 'services/subscriptionService';
import UsersService from 'services/usersService';
import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import { getMessage } from 'helpers/helper';
import M from 'messages';
import Subscription from './subscription';

import classes from './styles';

const Subscriptions = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // Use search params hook
  const [loading, setLoading] = useState(true);
  const [getProfileLoading, setGetProfileLoading] = useState({
    loading: true,
    loaded: false,
  });
  const [standardSubscriptionList, setStandardSubscriptionList] = useState([]);
  const [euSubscriptionList, setEuSubscriptionList] = useState([]);
  const [manageBillingLoading, setManageBillingLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [activePlans, setActivePlans] = useState([]);
  const currentUser = domainData.getCurrentUser();
  const pageParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const filteredParamsStore = subscriptionsStore.filter;
  const queryParams = {
    type: pageParams?.type || filteredParamsStore.type,
  }

  const planType = searchParams.get('type');
  const getSubscriptionPlans = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await SubscriptionService.getSubscriptionPlans();
        if (response?.data) {
          const standardPlans = [];
          const euPlans = [];
          response.data.forEach(subscription => {
            if (subscription.type === 'standard') {
              standardPlans.push(subscription);
            } else {
              euPlans.push(subscription);
            }
          });
          setStandardSubscriptionList(standardPlans);
          setEuSubscriptionList(euPlans);
        }
      } catch (err) {
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const getProfile = useCallback(
    async (currentPlanType) => {
      try {
        setGetProfileLoading({
          loading: true,
          loaded: false,
        });
        const response = await UsersService.getUserSubscriptions(currentUser.id);
        if (response && Object.keys(response.data).length) {
          setActivePlans(response.data.subscriptions.map(subscription => {
            const plan = {
              planId: subscription.planId,
              priceId: subscription.priceId,
              status: subscription.status,
              cancelAtPeriodEnd: subscription.cancelAtPeriodEnd,
              type: subscription.subscriptionPlan.type,
              stripeId: subscription.stripeId
            }
            if (plan.type === currentPlanType) {
              setCurrentPlan(plan);
            }
            return plan;
          }));
        }
      } catch (err) {
        enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
          variant: 'error',
        });
      } finally {
        setGetProfileLoading({
          loading: false,
          loaded: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );
  const setFilter = (filter) => {
    setSearchParams(filter);
    subscriptionsStore.setFilter(filter);
  };
  useEffect(() => {
    setFilter(queryParams);
    getSubscriptionPlans();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (planType && !getProfileLoading.loaded) {
      getProfile(planType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfile, getProfileLoading.loaded, planType]);

  const changePlanType = (_event, type) => {
    setSearchParams({ type }); // Update the URL query param
    setCurrentPlan(activePlans.find(plan => plan.type === type));
  };

  const handleManageBillingButton = async () => {
    try {
      setManageBillingLoading(true);
      const response = await SubscriptionService.createCustomerPortal();
      window.location.href = response.data.url;
      setManageBillingLoading(false);
    } catch (err) {
      setManageBillingLoading(false);
      enqueueSnackbar(getMessage(err?.response?.data, 'error'), {
        variant: 'error',
      });
    }
  };

  const drawSubscriptions = (subscriptions) =>
    subscriptions.map((subscription) => (
      planType === subscription.type && <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 3 }} key={subscription.id} >
        <Subscription
          subscription={subscription}
          currentPlan={currentPlan}
          getProfile={getProfile}
        />
      </Grid>
    )
    );

  return (
    <Box sx={classes.root}>
      <PageHeader
        title={M.get('subscriptions.pageTitle')}
        onlyGeneralPageTitle
      />

      <Typography sx={classes.plansTitle}>
        {M.get('subscriptions.choosePlan')}
      </Typography>
      <Typography sx={classes.plansDescription} mt={1}>
        {M.get('subscriptions.plansDescription')}
      </Typography>
      <Grid container mt={3}>
        {loading || getProfileLoading.loading || !planType
          ? <Loading />
          : <TabContext value={planType}>
            <Tabs
              onChange={changePlanType}
              aria-label="lab API tabs example"
              sx={classes.tabList}
              value={planType}
            >
              <Tab
                label={M.get('subscriptions.types.standard')}
                value="standard"
                sx={classes.primaryIconButton}
              />
              <Tab
                label={M.get('subscriptions.types.eu')}
                value="eu"
                sx={classes.primaryIconButton}
              />
              <Box sx={classes.customerPortalContainer}>
                <Button
                  variant='contained'
                  color='primary'
                  sx={{
                    ...classes.primaryIconButton,
                    '&.Mui-disabled': classes.disabledButton,
                    '&:hover': classes.primaryButtonHover
                  }}
                  disabled={manageBillingLoading}
                  onClick={handleManageBillingButton}
                >
                  {manageBillingLoading
                    ? <CircularProgress color="inherit" size={24} />
                    : M.get('subscriptions.customerPortal')
                  }
                </Button>
              </Box>
            </Tabs>
            <Box sx={classes.container}>
              {currentPlan
                ? currentPlan.status === 'active'
                  ? currentPlan.cancelAtPeriodEnd
                    ? <Alert severity="warning">{M.get('subscriptions.statuses.canceled')}</Alert>
                    : <Alert severity="success">{M.get('subscriptions.statuses.active')}</Alert>
                  : currentPlan.status === 'requested'
                    ? <Alert severity="warning">{M.get('subscriptions.statuses.freePlanRequestPending')}</Alert>
                    : <Alert severity="error">{M.get('subscriptions.statuses.error')}</Alert>
                : <Alert severity="info">{M.get('subscriptions.statuses.noSubscription')}</Alert>
              }
              <TabPanel value="standard">
                <Grid container spacing={2} mt={3}>
                  {drawSubscriptions(standardSubscriptionList)}
                </Grid>
              </TabPanel>
              <TabPanel value="eu">
                <Grid container spacing={2} mt={3}>
                  {drawSubscriptions(euSubscriptionList)}
                </Grid>
              </TabPanel>
            </Box>
          </TabContext>}
      </Grid>
    </Box>
  )
});

export default Subscriptions;